import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_patidarsagai/layout';
import * as actions from '../_redux/AuthActions';

function Logout() {
  const dispatch = useDispatch();
  const { hasAuthToken, refreshToken } = useSelector(
    ({ auth }) => ({
      hasAuthToken: Boolean(auth.accessToken),
      refreshToken: auth.refreshToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.logout({ refreshToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/" />;
}

export default Logout;
