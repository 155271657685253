import * as requestFromServer from "./NotificationCrud";
import { NotificationSlice, callTypes } from "./NotificationSlice";

const { actions } = NotificationSlice;

export const fetchNotification = (filterValue) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotification(filterValue)
    .then((response) => {
      dispatch(actions.notificationFetched(response.data));
      dispatch(actions.notificationFilterSet(filterValue));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const unmountNotificationList = () => (dispatch) => {
  dispatch(actions.notificationListUnmount());
};

export const fetchUnreadNotification = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUnreadNotification()
    .then((response) => {
      dispatch(actions.unreadNotificationFetched(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const fetchNewNotification = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNewNotification()
    .then((response) => {
      dispatch(actions.newNotificationFetched(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const fetchPushNotification = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPushNotification()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const fetchHistory = (filterValue) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHistory(filterValue)
    .then((response) => {
      dispatch(actions.historyFetched(response.data));
      dispatch(actions.historyFilterSet(filterValue));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const unmountHistoryList = () => (dispatch) => {
  dispatch(actions.historyListUnmount());
};

export const fetchLoginHistory = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLoginHistory()
    .then((response) => {
      dispatch(actions.loginHistoryFetched(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const acceptConnectRequest = (values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .acceptConnectRequest(values)
    .then((response) => {
      dispatch(actions.acceptConnectRequest(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const declineConnectRequest = (values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .declineConnectRequest(values)
    .then((response) => {
      dispatch(actions.declineConnectRequest(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const getPopupSetting = (professionalValue) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPopupSetting(professionalValue)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
