import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AuthSlice } from '../app/modules/Auth/_redux/AuthSlice';
import { NotificationSlice } from '../app/modules/Notification/_redux/NotificationSlice';
import { LandingSlice } from '../app/modules/Landing/_redux/LandingSlice';

export const rootReducer = combineReducers({
  auth: persistReducer(
    {
      storage,
      key: 'pv-auth',
      whitelist: ['user', 'accessToken', 'accessTokenExpirationTime', 'refreshToken', 'fcmToken'],
    },
    AuthSlice.reducer
  ),
  notification: NotificationSlice.reducer,
  landing: LandingSlice.reducer,
});
