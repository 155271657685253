import axios from 'axios';
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/Account/Login`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/Account/Logout`;
export const REFRESH_URL = `${process.env.REACT_APP_API_URL}/Account/Refresh`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/Account/Register`;
export const FORGET_PASSWORD = `${process.env.REACT_APP_API_URL}/Account/ForgetPassword`;
export const VERIFY_FORGET_PASSWORD = `${process.env.REACT_APP_API_URL}/Account/VerifyForgetPassword`;
export const GET_COUNTRY_LIST = `${process.env.REACT_APP_API_URL}/Country/Name`;
export const CHANGE_EMAIL_ADDRESS_OTP = `${process.env.REACT_APP_API_URL}/Account/ChangeEmailAddressOTP`;
export const SEND_OTP = `${process.env.REACT_APP_API_URL}/Account/SendOTP`;
export const VERIFY_OTP = `${process.env.REACT_APP_API_URL}/Account/VerifyOTP`;
export const SEND_REQUEST_OTP = `${process.env.REACT_APP_API_URL}/Account/SendRequestOTP`;
export const VERIFY_REQUEST_OTP = `${process.env.REACT_APP_API_URL}/Account/VerifyRequestOTP`;
export const GET_PROFILE_STATUS = `${process.env.REACT_APP_API_URL}/Account/Status`;

export function login(username, password, captchaResponse, fcmToken, ip, location, geoLocation) {
  return axios.post(LOGIN_URL, {
    username,
    password,
    captchaResponse,
    fcmToken,
    ip,
    location,
    geoLocation,
  });
}

export function logout(data) {
  return axios.post(LOGOUT_URL, data);
}

export function refresh(data) {
  return axios.post(REFRESH_URL, data);
}

export function register(
  firstName,
  lastName,
  email,
  phoneNumber,
  countryCode,
  password,
  captchaResponse
) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    email,
    phoneNumber,
    countryCode,
    password,
    captchaResponse,
  });
}

export function forgotPassword(email) {
  return axios.post(FORGET_PASSWORD, null, { params: { email } });
}

export function verifyForgotPassword(data) {
  return axios.post(VERIFY_FORGET_PASSWORD, data);
}

export function getCountryList() {
  return axios.get(GET_COUNTRY_LIST);
}

export function changeEmailAddressOTP(data) {
  return axios.post(CHANGE_EMAIL_ADDRESS_OTP, data);
}

export function sendOTP(Email) {
  return axios.post(SEND_OTP, null, { params: { Email } });
}

export function verifyOTP(Email, OTP, FcmToken, ip, location, geoLocation) {
  return axios.post(VERIFY_OTP, { Email, OTP, FcmToken, ip, location, geoLocation });
}

export function sendRequestOTP(Email) {
  return axios.post(SEND_REQUEST_OTP, null, { params: { Email } });
}

export function verifyRequestOTP(Email, OTP, FcmToken, ip, location, geoLocation) {
  return axios.post(VERIFY_REQUEST_OTP, { Email, OTP, FcmToken, ip, location, geoLocation });
}

export function getProfileStatus() {
  return axios.get(GET_PROFILE_STATUS);
}
