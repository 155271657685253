import axios from 'axios';
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/Account/Login`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/Account/Logout`;
export const REFRESH_URL = `${process.env.REACT_APP_API_URL}/Account/Refresh`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/Account/Register`;
export const FORGET_PASSWORD = `${process.env.REACT_APP_API_URL}/Account/ForgetPassword`;
export const VERIFY_FORGET_PASSWORD = `${process.env.REACT_APP_API_URL}/Account/VerifyForgetPassword`;
export const CHANGE_EMAIL_ADDRESS_OTP = `${process.env.REACT_APP_API_URL}/Account/ChangeEmailAddressOTP`;
export const SEND_OTP = `${process.env.REACT_APP_API_URL}/Account/SendOTP`;
export const VERIFY_OTP = `${process.env.REACT_APP_API_URL}/Account/VerifyOTP`;
export const SEND_REQUEST_OTP = `${process.env.REACT_APP_API_URL}/Account/SendRequestOTP`;
export const VERIFY_REQUEST_OTP = `${process.env.REACT_APP_API_URL}/Account/VerifyRequestOTP`;
export const GET_PROFILE_STATUS = `${process.env.REACT_APP_API_URL}/Account/Status`;

export async function login(
  username,
  password,
  captchaResponse,
  fcmToken,
  ip,
  location,
  geoLocation
) {
  const response = await axios.post(LOGIN_URL, {
    username,
    password,
    captchaResponse,
    fcmToken,
    ip,
    location,
    geoLocation,
  });
  return response.data;
}

export async function logout(data) {
  const response = await axios.post(LOGOUT_URL, data);
  return response.data;
}

export async function refresh(data) {
  const response = await axios.post(REFRESH_URL, data);
  return response.data;
}

export async function register(
  firstName,
  lastName,
  email,
  phoneNumber,
  countryCode,
  password,
  captchaResponse
) {
  const response = await axios.post(REGISTER_URL, {
    firstName,
    lastName,
    email,
    phoneNumber,
    countryCode,
    password,
    captchaResponse,
  });
  return response.data;
}

export async function forgotPassword(email) {
  const response = await axios.post(FORGET_PASSWORD, null, { params: { email } });
  return response.data;
}

export async function verifyForgotPassword(data) {
  const response = await axios.post(VERIFY_FORGET_PASSWORD, data);
  return response.data;
}

export async function changeEmailAddressOTP(data) {
  const response = await axios.post(CHANGE_EMAIL_ADDRESS_OTP, data);
  return response.data;
}

export async function sendOTP(Email) {
  const response = await axios.post(SEND_OTP, null, { params: { Email } });
  return response.data;
}

export async function verifyOTP(Email, OTP, FcmToken, ip, location, geoLocation) {
  const response = await axios.post(VERIFY_OTP, {
    Email,
    OTP,
    FcmToken,
    ip,
    location,
    geoLocation,
  });
  return response.data;
}

export async function sendRequestOTP(Email) {
  const response = await axios.post(SEND_REQUEST_OTP, null, { params: { Email } });
  return response.data;
}

export async function verifyRequestOTP(Email, OTP, FcmToken, ip, location, geoLocation) {
  const response = await axios.post(VERIFY_REQUEST_OTP, {
    Email,
    OTP,
    FcmToken,
    ip,
    location,
    geoLocation,
  });
  return response.data;
}

export async function getProfileStatus() {
  const response = await axios.get(GET_PROFILE_STATUS);
  return response.data;
}
