/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import objectPath from 'object-path';
import { NavLink } from 'react-router-dom';
import { useHtmlClassService } from '../../../_core/PatidarVivahLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { useTranslation } from 'react-i18next';
import Config from '../../../../../constants/Config';

export function UserProfileDropdown() {
  const [t] = useTranslation('UserProfileDropdown');
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    };
  }, [uiService]);

  const { newNotificationList } = useSelector(
    (state) => ({
      newNotificationList: state.notification.newNotificationList,
    }),
    shallowEqual
  );

  const addDefaultSrc = (ev) => {
    ev.target.src = Config.NoProfileImageUrl;
  };

  return user ? (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div
          className={
            'btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto'
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{' '}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.firstName} {user.lastName}
          </span>
          <span className="symbol symbol-35">
            <img onError={addDefaultSrc} src={toAbsoluteUrl(user.profileImage)} alt="" />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img onError={addDefaultSrc} src={toAbsoluteUrl(user.profileImage)} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstName} {user.lastName}
                  {user.profileId && (
                    <div className="font-size-sm text-muted">{user.profileId}</div>
                  )}
                </div>
                <Link to="/notification/list">
                  <span className="label label-light-success label-lg font-weight-bold label-inline">
                    {newNotificationList.length} {t('NOTIFICATIONS')}
                  </span>
                </Link>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.firstName}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstName} {user.lastName}
                {user.profileId && <div className="font-size-sm text-muted">{user.profileId}</div>}
              </div>
              <span className="label label-success label-lg font-weight-bold label-inline">
                {newNotificationList.length} {t('NOTIFICATIONS')}
              </span>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">{t('MY_PROFILE')}</div>
                <div className="text-muted">
                  {t('PROFILE_INFORMATION_AND_MORE')}
                  {/* <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> */}
                </div>
              </div>
            </div>
          </Link>

          <Link to="/profile/shortlist" className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="far fa-star text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{t('MY_SHORTLISTED')}</div>
                <div className="text-muted">{t('SHORTLISTED_PROFILES')}</div>
              </div>
            </div>
          </Link>

          <Link to="/notification/activity" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{t('MY_ACTIVITIES')}</div>
                <div className="text-muted">{t('LOGS_AND_NOTIFICATIONS')}</div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              {t('SIGN_OUT')}
            </Link>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div
          className={
            'btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto'
          }
        >
          <NavLink to="/auth/login">
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-2 ml-2">
              {t('LOGIN')}
            </span>
          </NavLink>
        </div>
      </Dropdown.Toggle>
    </Dropdown>
  );
}
