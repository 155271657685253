import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import objectPath from 'object-path';
import React, { useMemo, useState } from 'react';
import { Dropdown, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import { getNewNotification } from '../../../../../api';
import { showSwal } from '../../../../../constants/Common';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { useHtmlClassService } from '../../../_core/PatidarVivahLayout';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const [t] = useTranslation('Notification');
  const [key, setKey] = useState('Alerts');
  const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.notifications.layout') === 'offcanvas',
    };
  }, [uiService]);

  const notificationQuery = useQuery({
    queryKey: ['notificationQuery'],
    queryFn: () => getNewNotification().catch((error) => showSwal(error)),
  });

  const newNotification = () => {
    if (notificationQuery.data?.length) {
      return notificationQuery.data?.map((r) => {
        return (
          <NavLink to="/notification/list" className="navi-item" key={r.id}>
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-paper-plane text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{r.title}</div>
                <div className="text-muted">{moment.utc(r.createdOn).local().fromNow()}</div>
              </div>
            </div>
          </NavLink>
        );
      });
    } else if (notificationQuery.data?.length === 0 && notificationQuery.isLoading === false) {
      return <div className="p-3">{t('NOTIFICATION_NOT_FOUND_MSG')}</div>;
    } else {
      return <div className="p-3"></div>;
    }
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="user-notification-tooltip">{t('NOTIFICATIONS')}</Tooltip>}
            >
              <div
                className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
                </span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">{t('NOTIFICATIONS')}</span>
                  <NavLink
                    to="/notification/list"
                    className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
                  >
                    {notificationQuery.data?.length} new
                  </NavLink>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li"></Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" id="topbar_notifications_alerts">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: '300px', position: 'relative' }}
                      >
                        {newNotification()}
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
