import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { showSwal } from '../../../../../constants/Common';
import {
  getAnnualIncome,
  getCityList,
  getCountryList,
  getEducationList,
  getOccupationList,
  getStateList,
  getUser,
  postProfessionalValueDetailsData,
} from '../../../../../api';
import { visaType } from '../../../../../constants/List';
import KTWizard from '../../../../../_patidarsagai/_assets/js/components/wizard';

export default function ProfessionalDetails() {
  const [t] = useTranslation('ProfileWizard');
  const [loading, setLoading] = useState(false);

  const memberDetailQuery = useQuery({
    queryKey: ['memberDetailQuery'],
    queryFn: () => getUser().catch((error) => showSwal(error)),
  });
  const [stateId, setStateId] = useState(memberDetailQuery.data?.stateId);

  const educationListQuery = useQuery({
    queryKey: [`educationListQuery`],
    queryFn: () => getEducationList().catch((error) => showSwal(error)),
  });

  const annualIncomeListQuery = useQuery({
    queryKey: [`annualIncomeListQuery`],
    queryFn: () => getAnnualIncome().catch((error) => showSwal(error)),
  });

  const occupationListQuery = useQuery({
    queryKey: [`occupationListQuery`],
    queryFn: () => getOccupationList().catch((error) => showSwal(error)),
  });

  const countryListQuery = useQuery({
    queryKey: [`countryListQuery`],
    queryFn: () => getCountryList().catch((error) => showSwal(error)),
  });

  const [countryId, setCountryId] = useState(
    memberDetailQuery.data?.countryId === null
      ? countryListQuery.data?.find((item) => item.name === 'India')?.id
      : memberDetailQuery.data?.countryId
  );

  const stateListQuery = useQuery({
    queryKey: [`stateListQuery`, countryId],
    queryFn: () => getStateList(countryId).catch((error) => showSwal(error)),
    enabled: !!countryId,
  });

  const cityListQuery = useQuery({
    queryKey: [`cityListQuery`, stateId],
    queryFn: () => getCityList(stateId).catch((error) => showSwal(error)),
    enabled: !!stateId,
  });

  const getInputClasses = (fieldname) => {
    if (formikProfessional.touched[fieldname] && formikProfessional.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formikProfessional.touched[fieldname] && !formikProfessional.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const goToPrev = () => {
    const wizard = new KTWizard('kt_wizard');
    wizard.goPrev();
  };

  const formikProfessional = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryId: memberDetailQuery.data?.countryId || 92,
      state: memberDetailQuery.data?.state || 'Gujarat',
      city: memberDetailQuery.data?.city || '',
      isNRI: memberDetailQuery
        ? memberDetailQuery.data?.isNRI
          ? memberDetailQuery.data?.isNRI
          : memberDetailQuery.data?.isNRI === false
          ? false
          : ''
        : '',
      visaType: memberDetailQuery.data?.visaType || '',
      educationId: memberDetailQuery.data?.educationId || '',
      educationDetails: memberDetailQuery.data?.educationDetails || '',
      occupationId: memberDetailQuery.data?.occupationId || '',
      occupationDetails: memberDetailQuery.data?.occupationDetails || '',
      annualIncomeId: memberDetailQuery.data?.annualIncomeId || '',
      about: memberDetailQuery.data?.about || '',
    },
    validationSchema: Yup.object().shape({
      countryId: Yup.string().required(t('professional_Details.VALIDATION.COUNTRY_REQUIRED')),
      state: Yup.string().required(t('professional_Details.VALIDATION.STATE_REQUIRED')),
      city: Yup.string().required(t('professional_Details.VALIDATION.CITY_REQUIRED')),
      isNRI: Yup.string().required(t('professional_Details.VALIDATION.NRI_REQUIRED')),
      visaType: Yup.string().when('isNRI', {
        is: (val) => (val === ('true' || true) ? true : false),
        then: Yup.string().required(t('professional_Details.VALIDATION.VISA_TYPE_REQUIRED')),
      }),
      educationId: Yup.string().required(t('professional_Details.VALIDATION.EDUCATION_REQUIRED')),
      occupationId: Yup.string().required(t('professional_Details.VALIDATION.OCCUPATION_REQUIRED')),
      annualIncomeId:
        memberDetailQuery.data?.gender === true
          ? Yup.string().required(t('professional_Details.VALIDATION.ANNUAL_INCOME_REQUIRED'))
          : Yup.string(),
      about: Yup.string().max(4000, t('professional_Details.VALIDATION.ABOUT_MAX')),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      values['id'] = memberDetailQuery.data?.id;
      postProfessionalValueDetailsData(values)
        .then(() => {
          const wizard = new KTWizard('kt_wizard');
          wizard.goTo(4);
          setSubmitting(false);
          setLoading(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setLoading(false);
          showSwal(error);
        });
    },
  });

  // const countryCheck = (val) => {
  //   if (val) {
  //     dispatch(actions.fetchStateList(val.id, member.state))
  //       .then(() => {
  //         formikProfessional.setFieldValue('state', '');
  //         formikProfessional.setFieldValue('city', '');
  //       })
  //       .catch((error) => showSwal(error));
  //   }
  // };

  // const stateCheck = (val) => {
  //   if (val) {
  //     dispatch(actions.fetchCityList(val.id))
  //       .then(() => {
  //         formikProfessional.setFieldValue('city', '');
  //       })
  //       .catch((error) => showSwal(error));
  //   }
  // };

  const countryCheck = (val) => {
    if (val.id) {
      setCountryId(val.id);
    }
    formikProfessional.setFieldValue('state', '');
    formikProfessional.setFieldValue('city', '');
  };

  const stateCheck = (val) => {
    if (val.id) {
      setStateId(val.id);
    }
    formikProfessional.setFieldValue('city', '');
  };

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content">
        <form onSubmit={formikProfessional.handleSubmit} id="kt_form_professional">
          <div className="row">
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.NRI')}</label>
                <div className="radio-inline">
                  <label className="radio">
                    <input
                      className={`${getInputClasses('isNRI')}`}
                      type="radio"
                      name="isNRI"
                      onChange={(e) => {
                        formikProfessional.setFieldValue('visaType', '');
                        formikProfessional.setFieldValue('isNRI', !e.target.checked);
                      }}
                      checked={
                        formikProfessional && formikProfessional.values.isNRI === ''
                          ? false
                          : (formikProfessional && !formikProfessional.values.isNRI) || false
                      }
                    />
                    <span></span>
                    {t('professional_Details.NRI_NO')}
                  </label>
                  <label className="radio">
                    <input
                      className={`${getInputClasses('isNRI')}`}
                      type="radio"
                      name="isNRI"
                      onChange={(e) => formikProfessional.setFieldValue('isNRI', e.target.checked)}
                      checked={
                        formikProfessional && formikProfessional.values.isNRI === ''
                          ? false
                          : (formikProfessional && formikProfessional.values.isNRI) || false
                      }
                    />
                    <span></span>
                    {t('professional_Details.NRI_YES')}
                  </label>
                </div>
                {formikProfessional.touched.isNRI && formikProfessional.errors.isNRI ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.isNRI}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-6">
              {/*begin::Input*/}
              {formikProfessional.values.isNRI && (
                <div className="form-group">
                  <label>{t('professional_Details.VISA_TYPE')}</label>
                  <select
                    name="visaType"
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'visaType'
                    )}`}
                    {...formikProfessional.getFieldProps('visaType')}
                  >
                    <option value="">Select</option>
                    {visaType.map((list) => {
                      return (
                        <option key={list.id} value={list.value}>
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                  {formikProfessional.touched.visaType && formikProfessional.errors.visaType ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formikProfessional.errors.visaType}</div>
                    </div>
                  ) : null}
                </div>
              )}
              {/*end::Input*/}
            </div>
          </div>
          <h4 className="mb-10 font-weight-bold text-dark">
            {t('professional_Details.CURRENT_ADDRESS')}
          </h4>
          <div className="row">
            {formikProfessional.values.isNRI && (
              <div className="col-xl-4">
                {/*begin::Input*/}
                <div className="form-group">
                  <label>{t('professional_Details.COUNTRY')}</label>
                  <Typeahead
                    selected={countryListQuery.data?.filter(
                      (c) => c.id === formikProfessional.values.countryId
                    )}
                    onChange={(e) => {
                      if (e.length) {
                        formikProfessional.setFieldValue('countryId', e[0].id);
                        countryCheck(e[0]);
                      } else formikProfessional.setFieldValue('countryId', '');
                    }}
                    inputProps={{
                      className: `form-control-solid-bg ${getInputClasses('countryId')}`,
                    }}
                    size="lg"
                    id="countryTypeahead"
                    labelKey="name"
                    options={countryListQuery.data || []}
                    placeholder={t('professional_Details.COUNTRY_PH')}
                  />
                  {formikProfessional.touched.countryId && formikProfessional.errors.countryId ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formikProfessional.errors.countryId}</div>
                    </div>
                  ) : null}
                </div>
                {/*end::Input*/}
              </div>
            )}
            <div className={formikProfessional.values.isNRI ? 'col-xl-4' : 'col-xl-6'}>
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.STATE')}</label>
                <Typeahead
                  selected={
                    stateListQuery.data?.filter((c) => c.name === formikProfessional.values.state)
                      .length > 0
                      ? stateListQuery.data?.filter(
                          (c) => c.name === formikProfessional.values.state
                        )
                      : [{ id: 0, name: formikProfessional.values.state }]
                  }
                  onInputChange={(input, e) => formikProfessional.setFieldValue('state', input)}
                  onChange={(e) => {
                    if (e.length) {
                      formikProfessional.setFieldValue('state', e[0].name);
                      stateCheck(e[0]);
                    }
                  }}
                  inputProps={{
                    className: `form-control-solid-bg ${getInputClasses('state')}`,
                  }}
                  size="lg"
                  id="stateTypeahead"
                  labelKey="name"
                  options={stateListQuery.data || []}
                  placeholder={t('professional_Details.STATE_PH')}
                />
                {formikProfessional.touched.state && formikProfessional.errors.state ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.state}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className={formikProfessional.values.isNRI ? 'col-xl-4' : 'col-xl-6'}>
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.CITY')}</label>
                <Typeahead
                  selected={
                    cityListQuery.data?.filter((c) => c.name === formikProfessional.values.city)
                      .length > 0
                      ? cityListQuery.data?.filter((c) => c.name === formikProfessional.values.city)
                      : [{ id: 0, name: formikProfessional.values.city }]
                  }
                  onInputChange={(input) => formikProfessional.setFieldValue('city', input)}
                  onChange={(e) => e.length && formikProfessional.setFieldValue('city', e[0].name)}
                  inputProps={{
                    className: `form-control-solid-bg ${getInputClasses('city')}`,
                  }}
                  size="lg"
                  id="cityTypeahead"
                  labelKey="name"
                  options={cityListQuery.data || []}
                  placeholder={t('professional_Details.CITY_PH')}
                />
                {formikProfessional.touched.city && formikProfessional.errors.city ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.city}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.EDUCATION')}</label>
                <Typeahead
                  clearButton
                  selected={educationListQuery.data?.filter(
                    (c) => c.id === formikProfessional.values.educationId
                  )}
                  onChange={(e) => {
                    e.length
                      ? e.map((c) => formikProfessional.setFieldValue('educationId', c.id))
                      : formikProfessional.setFieldValue('educationId', '');
                  }}
                  inputProps={{
                    className: `form-control-solid-bg ${getInputClasses('educationId')}`,
                  }}
                  size="lg"
                  id="educationList"
                  labelKey="name"
                  options={educationListQuery.data || []}
                  placeholder={t('professional_Details.EDUCATION_PH')}
                />
                {formikProfessional.touched.educationId && formikProfessional.errors.educationId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.educationId}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.EDUCATION_DETAILS')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'educationDetails'
                  )}`}
                  name="educationDetails"
                  placeholder={t('professional_Details.EDUCATION_DETAILS_PH')}
                  {...formikProfessional.getFieldProps('educationDetails')}
                />
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.ANNUAL_INCOME')}</label>
                <select
                  name="annualIncomeId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'annualIncomeId'
                  )}`}
                  {...formikProfessional.getFieldProps('annualIncomeId')}
                >
                  <option value="">Select</option>
                  {annualIncomeListQuery.data?.map((list) => {
                    return (
                      <option key={list.id} value={list.id}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
                {formikProfessional.touched.annualIncomeId &&
                formikProfessional.errors.annualIncomeId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.annualIncomeId}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-5">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.OCCUPATION')}</label>
                <select
                  name="occupationId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'occupationId'
                  )}`}
                  {...formikProfessional.getFieldProps('occupationId')}
                >
                  <option value="">Select</option>
                  {occupationListQuery.data
                    ?.filter((oct) => oct.occupationType.includes('1'))
                    .map((list) => {
                      return (
                        <option key={list.id} value={list.id}>
                          {list.name}
                        </option>
                      );
                    })}
                </select>
                {formikProfessional.touched.occupationId &&
                formikProfessional.errors.occupationId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikProfessional.errors.occupationId}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-7">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('professional_Details.OCCUPATION_DETAILS')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'occupationDetails'
                  )}`}
                  name="occupationDetails"
                  placeholder={t('professional_Details.OCCUPATION_DETAILS_PH')}
                  {...formikProfessional.getFieldProps('occupationDetails')}
                />
                <span className="text-secondary">
                  {' '}
                  વ્યવસાયનું નામ, સ્થળ, નોકરી હોય તો હોદ્દો વગેરે માહિતી વિગતમાં લખવી.
                </span>
              </div>
              {/*end::Input*/}
            </div>
          </div>
          {/*begin::Input*/}
          <div className="form-group">
            <label>{t('professional_Details.ABOUT_YOU')}</label>
            <textarea
              type="text"
              rows="4"
              className={`form-control form-control-solid form-control-lg ${getInputClasses(
                'about'
              )}`}
              {...formikProfessional.getFieldProps('about')}
              name="about"
              placeholder={t('professional_Details.ABOUT_YOU_PH')}
            />
            {formikProfessional.touched.about && formikProfessional.errors.about ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formikProfessional.errors.about}</div>
              </div>
            ) : null}
          </div>
          {/*end::Input*/}
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div className="mr-2">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-prev"
                onClick={() => goToPrev()}
              >
                Previous
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                disabled={loading}
              >
                <span>Save & Continue</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
