import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { getCommunity, getHeightList, getUser, postPersonalDetailsData } from '../../../../../api';
import { showSwal } from '../../../../../constants/Common';
import { maritalStatus } from '../../../../../constants/List';
import KTWizard from '../../../../../_patidarsagai/_assets/js/components/wizard';

export default function PersonalDetails() {
  const [t] = useTranslation('ProfileWizard');
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(30, t('Personal_Details.VALIDATION.FIRST_NAME_MAX'))
      .required(t('Personal_Details.VALIDATION.FIRST_NAME_REQUIRED')),
    lastName: Yup.string()
      .max(30, t('Personal_Details.VALIDATION.LAST_NAME_MAX'))
      .required(t('Personal_Details.VALIDATION.LAST_NAME_REQUIRED')),
    communityId: Yup.string().required(t('Personal_Details.VALIDATION.COMMUNITY_REQUIRED')),
    maritalStatus: Yup.string().required(t('Personal_Details.VALIDATION.MARITAL_STATUS_REQUIRED')),
    disability: Yup.string().when('anyDisability', {
      is: (val) => (val === ('true' || true) ? true : false),
      then: Yup.string().required(t('Personal_Details.VALIDATION.DISABILITY_NAME_REQUIRED')),
    }),
    anyDisability: Yup.string().required(t('Personal_Details.VALIDATION.ANY_DISABILITY_REQUIRED')),
    heightId: Yup.string().required(t('Personal_Details.VALIDATION.HEIGHT_REQUIRED')),
    birthDate: Yup.date()
      .required(t('Personal_Details.VALIDATION.DATE_OF_BIRTH_REQUIRED'))
      .test('birthDate', t('Personal_Details.VALIDATION.DATE_OF_BIRTH_VALID'), (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      }),
    gender: Yup.string().required(t('Personal_Details.VALIDATION.GENDER_REQUIRED')),
    parentCommunityId: Yup.string().when('communityId', {
      is: (val) => (val ? false : true),
      then: Yup.string().required(t('Personal_Details.VALIDATION.PARENT_COMMUNITY_REQUIRED')),
    }),
  });

  const memberDetailQuery = useQuery({
    queryKey: ['memberDetailQuery'],
    queryFn: () => getUser().catch((error) => showSwal(error)),
  });
  const communityListQuery = useQuery({
    queryKey: ['communityListQuery'],
    queryFn: () => getCommunity().catch((error) => showSwal(error)),
  });
  const heightListQuery = useQuery({
    queryKey: ['heightListQuery'],
    queryFn: () => getHeightList().catch((error) => showSwal(error)),
  });

  const getInputClasses = (fieldname) => {
    if (formikPersonal.touched[fieldname] && formikPersonal.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formikPersonal.touched[fieldname] && !formikPersonal.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };
  const formikPersonal = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: memberDetailQuery.data?.firstName || '',
      lastName: memberDetailQuery.data?.lastName || '',
      communityId: memberDetailQuery.data?.communityId || '',
      maritalStatus: memberDetailQuery.data?.maritalStatus || '',
      disability: memberDetailQuery.data?.disability || '',
      anyDisability: memberDetailQuery.data
        ? memberDetailQuery.data?.anyDisability
          ? memberDetailQuery.data?.anyDisability
          : memberDetailQuery.data?.anyDisability === false
          ? false
          : ''
        : '',
      heightId: memberDetailQuery.data?.heightId || '',
      weight: memberDetailQuery.data?.weight || '',
      birthDate:
        (memberDetailQuery.data?.birthDate &&
          moment(memberDetailQuery.data?.birthDate).format('YYYY-MM-DD')) ||
        '',
      gender: memberDetailQuery.data
        ? memberDetailQuery.data?.gender
          ? memberDetailQuery.data?.gender
          : memberDetailQuery.data?.gender === false
          ? false
          : ''
        : '',
      parentCommunityId: memberDetailQuery.data?.parentCommunityId || '',
    },
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      values['id'] = memberDetailQuery.data?.id;
      values['profileId'] = memberDetailQuery.data?.profileId;
      postPersonalDetailsData(values)
        .then(() => {
          memberDetailQuery.refetch();
          const wizard = new KTWizard('kt_wizard');
          wizard.goTo(2);
          setSubmitting(false);
          setLoading(false);
          // refresh({ refreshToken: refreshToken.authState });
        })
        .catch((error) => {
          setSubmitting(false);
          setLoading(false);
          showSwal(error);
        });
    },
  });

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <form onSubmit={formikPersonal.handleSubmit} id="kt_form_personal">
          <div className="row">
            {/* ########################## First Name ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.FIRST_NAME')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'firstName'
                  )}`}
                  name="firstName"
                  placeholder={t('Personal_Details.FIRST_NAME_PH')}
                  {...formikPersonal.getFieldProps('firstName')}
                />
                {formikPersonal.touched.firstName && formikPersonal.errors.firstName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.firstName}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Sur Name ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.LAST_NAME')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'lastName'
                  )}`}
                  name="lastName"
                  placeholder={t('Personal_Details.LAST_NAME_PH')}
                  {...formikPersonal.getFieldProps('lastName')}
                />
                {formikPersonal.touched.lastName && formikPersonal.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.lastName}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
          </div>

          <div className="row">
            {/* ########################## Community ########################## */}
            <div className="col-xl-12">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.COMMUNITY')}</label>
                <div className="row">
                  <div className="col-lg-4">
                    <select
                      name="parentCommunityId"
                      className={`form-control form-control-solid form-control-lg ${getInputClasses(
                        'parentCommunityId'
                      )}`}
                      onChange={(e) => {
                        formikPersonal.setFieldValue('parentCommunityId', e.target.value);
                        formikPersonal.setFieldValue('communityId', '');
                      }}
                      value={
                        communityListQuery.data?.length && formikPersonal.values.communityId
                          ? communityListQuery.data?.find(
                              (c) => c.id === formikPersonal.values.communityId
                            ).parentCommunityId
                          : formikPersonal.values.parentCommunityId
                      }
                      // {...formikPersonal.getFieldProps("communityGroup")}
                    >
                      <option value="">Select</option>
                      <option value="1">Kadva Patidar - કડવા પાટીદાર</option>
                      <option value="2">Leva Patidar - લેવા પાટીદાર</option>
                    </select>
                    {formikPersonal.touched.parentCommunityId &&
                    formikPersonal.errors.parentCommunityId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formikPersonal.errors.parentCommunityId}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-8">
                    {communityListQuery.data?.length && (
                      <Typeahead
                        clearButton
                        selected={communityListQuery.data?.filter(
                          (c) => c.id === formikPersonal.values.communityId
                        )}
                        onChange={(e) => {
                          e.length
                            ? e.map((c) => formikPersonal.setFieldValue('communityId', c.id))
                            : formikPersonal.setFieldValue('communityId', '');
                        }}
                        inputProps={{
                          className: `form-control-solid-bg ${getInputClasses('communityId')}`,
                        }}
                        size="lg"
                        id="communityList"
                        labelKey="name"
                        options={
                          communityListQuery.data?.filter(
                            (c) =>
                              c.parentCommunityId ===
                              parseInt(formikPersonal.values.parentCommunityId)
                          ) || []
                        }
                        placeholder={t('Personal_Details.COMMUNITY_PH')}
                      />
                    )}
                    {formikPersonal.touched.communityId && formikPersonal.errors.communityId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formikPersonal.errors.communityId}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/*end::Input*/}
            </div>
          </div>

          <div className="row">
            {/* ########################## Height ########################## */}
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.HEIGHT')}</label>
                <select
                  name="heightId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'heightId'
                  )}`}
                  {...formikPersonal.getFieldProps('heightId')}
                >
                  <option value="">Select</option>
                  {heightListQuery.data?.map((list) => {
                    return (
                      <option key={list.id} value={list.id}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
                {formikPersonal.touched.heightId && formikPersonal.errors.heightId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.heightId}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Weight ########################## */}
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group ">
                <label>{t('Personal_Details.WEIGHT')}</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'weight'
                    )}`}
                    name="weight"
                    placeholder={t('Personal_Details.WEIGHT_PH')}
                    {...formikPersonal.getFieldProps('weight')}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      KG
                    </span>
                  </div>
                </div>
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Date Of Birth ########################## */}
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.DATE_OF_BIRTH')}</label>
                <input
                  type="date"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'birthDate'
                  )}`}
                  name="birthDate"
                  placeholder={t('Personal_Details.DATE_OF_BIRTH_PH')}
                  {...formikPersonal.getFieldProps('birthDate')}
                />
                {formikPersonal.touched.birthDate && formikPersonal.errors.birthDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.birthDate}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Marital Status ########################## */}
            <div className="col-xl-5">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.MARITAL_STATUS')}</label>
                <select
                  name="maritalStatus"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'maritalStatus'
                  )}`}
                  {...formikPersonal.getFieldProps('maritalStatus')}
                >
                  <option value="">Select</option>
                  {maritalStatus.map((list) => {
                    return (
                      <option key={list.id} value={list.value}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
                {formikPersonal.touched.maritalStatus && formikPersonal.errors.maritalStatus ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.maritalStatus}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Any Disability ########################## */}
            <div className="col-xl-3">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.ANY_DISABILITY')}</label>
                <div className="radio-inline">
                  <label className="radio">
                    <input
                      className={`${getInputClasses('anyDisability')}`}
                      type="radio"
                      name="anyDisability"
                      onChange={(e) => {
                        formikPersonal.setFieldValue('disability', '');
                        formikPersonal.setFieldValue('anyDisability', !e.target.checked);
                      }}
                      checked={
                        formikPersonal && formikPersonal.values.anyDisability === ''
                          ? false
                          : (formikPersonal && !formikPersonal.values.anyDisability) || false
                      }
                    />
                    <span></span>
                    {t('Personal_Details.DISABILITY_NO')}
                  </label>
                  <label className="radio">
                    <input
                      className={`${getInputClasses('anyDisability')}`}
                      type="radio"
                      name="anyDisability"
                      onChange={(e) =>
                        formikPersonal.setFieldValue('anyDisability', e.target.checked)
                      }
                      checked={
                        formikPersonal && formikPersonal.values.anyDisability === ''
                          ? false
                          : (formikPersonal && formikPersonal.values.anyDisability) || false
                      }
                    />
                    <span></span>
                    {t('Personal_Details.DISABILITY_YES')}
                  </label>
                </div>
                {formikPersonal.touched.anyDisability && formikPersonal.errors.anyDisability ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.anyDisability}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
            <div className="col-xl-4">
              {/*begin::Input*/}
              {formikPersonal.values.anyDisability && (
                <div className="form-group">
                  <label></label>
                  <input
                    type="text"
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'disability'
                    )}`}
                    name="disability"
                    placeholder={t('Personal_Details.DISABILITY_NAME_PH')}
                    {...formikPersonal.getFieldProps('disability')}
                  />
                  {formikPersonal.touched.disability && formikPersonal.errors.disability ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formikPersonal.errors.disability}</div>
                    </div>
                  ) : null}
                </div>
              )}
              {/*end::Input*/}
            </div>

            {/* ########################## Gender ########################## */}
            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('Personal_Details.I_AM')}</label>
                <div className="radio-inline">
                  <label className="radio">
                    <input
                      className={`${getInputClasses('gender')}`}
                      type="radio"
                      name="gender"
                      onChange={(e) => formikPersonal.setFieldValue('gender', e.target.checked)}
                      checked={
                        formikPersonal && formikPersonal.values.gender === ''
                          ? false
                          : (formikPersonal && formikPersonal.values.gender) || false
                      }
                    />
                    <span></span>
                    {t('Personal_Details.MALE')}
                  </label>
                  <label className="radio">
                    <input
                      className={`${getInputClasses('gender')}`}
                      type="radio"
                      name="gender"
                      onChange={(e) => formikPersonal.setFieldValue('gender', !e.target.checked)}
                      checked={
                        formikPersonal && formikPersonal.values.gender === ''
                          ? false
                          : (formikPersonal && !formikPersonal.values.gender) || false
                      }
                    />
                    <span></span>
                    {t('Personal_Details.FEMALE')}
                  </label>
                </div>
                {formikPersonal.touched.gender && formikPersonal.errors.gender ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikPersonal.errors.gender}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>
          </div>
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                disabled={loading}
              >
                <span>Save & Continue</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
            <div className="pt-4">
              <span>નોંધ: કોઈપણ ખોટી માહિતી આપવી એ ગુનાને પાત્ર છે.</span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
