import axios from 'axios';

export async function getUser() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Profile`);
  return response.data;
}

export async function postPersonalDetails(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Profile/PersonalDetails`,
    values
  );
  return response.data;
}

export async function postLifestyleDetails(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Profile/FamilyDetails`,
    values
  );
  return response.data;
}

export async function postProfessionalValueDetails(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Profile/ProfessionalDetails`,
    values
  );
  return response.data;
}

export async function postUploadPhotoDetails(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/MemberImage/UploadPhoto`,
    values
  );
  return response.data;
}

export async function postUploadProfilePhoto(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/Profile/UploadPhoto`, values);
  return response.data;
}

export async function getSatapetaProfile(ProfileId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/Profile/Satapeta/${ProfileId}`
  );
  return response.data;
}

export async function updateSatapetaSetting(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Profile/VerifySatapetaSetting`,
    values
  );
  return response.data;
}

export async function sendSatapetaSetting(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/Profile/SendSatapetaSetting`,
    values
  );
  return response.data;
}

export async function postPartnerPreferences(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Profile/PartnerPreferenceAddEdit`,
    values
  );
  return response.data;
}

export async function getPartnerPreferences(values) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/Profile/PartnerPreference/${values}`
  );
  return response.data;
}

export async function getRequest() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Request/Name`);
  return response.data;
}

export async function postRequest(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/MemberRequest`, values);
  return response.data;
}

export async function changePassword(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Account/ChangePassword`,
    values
  );
  return response.data;
}

export async function changePhoneNumber(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Account/ChangePhoneNumber`,
    values
  );
  return response.data;
}

export async function changeEmailAddress(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Account/ChangeEmailAddress`,
    values
  );
  return response.data;
}

export async function verifyEmailAddress(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Account/VerifyEmailAddress`,
    values
  );
  return response.data;
}

export async function setDeactiveAccount() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Account/DeActiveAccount`);
  return response.data;
}

export async function setActiveAccount() {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Account/ActiveAccount`);
  return response.data;
}

export async function setDeleteAccount(password) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Account/DeleteAccount`,
    null,
    { params: { password } }
  );
  return response.data;
}

export async function getMemberImages(values) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/MemberImage/${values}`);
  return response.data;
}

export async function removeMemberImage(values) {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/MemberImage/${values}`);
  return response.data;
}

export async function setDefaultMemberImage(values) {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/MemberImage/Default`, values);
  return response.data;
}

export async function getShortListedMe(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Profile/ShortListedMe`,
    values
  );
  return response.data;
}

export async function getVisitedMe(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Profile/VisitedMe`, values);
  return response.data;
}

export async function getBioData() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/PVBioData/GetPVBioData`,
    ('download-pdf', { responseType: 'arraybuffer' })
  );
  return response.data;
}

export async function getNotificationTypeList() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/NotificationSetting`);
  return response.data;
}

export async function saveNotificationSetting(data) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/NotificationSetting`, data);
  return response.data;
}

export async function getNotificationSettingByUserId(userId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/NotificationSetting/${userId}`
  );
  return response.data;
}
