import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import toastr from 'toastr';
import * as Yup from 'yup';
import * as actions from '../_redux/AuthActions';

function Login(props) {
  const [t] = useTranslation('Auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.actionsLoading, shallowEqual);
  const fcmToken = useSelector((state) => state.auth.fcmToken, shallowEqual);

  const [ipLocData, setIPlocData] = useState({ ip: null, location: null, geoLocation: null });

  let reCaptchaObj = React.useRef();
  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string().required(t('login.VALIDATION.EMAIL_PHONE_NUMBER_REQUIRED')),
    password: Yup.string()
      .min(6, t('login.VALIDATION.PASSWORD_MIN'))
      .max(100, t('login.VALIDATION.PASSWORD_MAX'))
      .required(t('login.VALIDATION.PASSWORD_REQUIRED')),
    captchaResponse: Yup.string(),
  });
  const initialValues = {
    phoneNumber: '',
    password: '',
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      dispatch(
        actions.login(
          values.phoneNumber,
          values.password,
          values.captchaResponse,
          fcmToken,
          ipLocData.ip,
          ipLocData.location,
          ipLocData.geoLocation
        )
      )
        .then((response) => {
          if (!response.data.user.emailConfirmed) {
            toastr.success(
              `OTP sent successfully to ${response.data.user.email}.`,
              'Patidar Sagai'
            );
            history.push('/auth/verify-otp');
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.join('<br/>'));
        });
    },
  });

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((data) => {
        setIPlocData({
          ip: data?.ip,
          geoLocation: `${data?.latitude},${data?.longitude}`,
          location: `${data?.city}, ${data?.region}, ${data?.country_name}:${data?.postal}`,
        });
      });

    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        formik.setSubmitting(true);
        reCaptchaObj.current.reset();
        reCaptchaObj.current.execute();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SendOtpRequest = () => {
    formik.setStatus();
    dispatch(actions.sendRequestOTP(formik.values.phoneNumber))
      .then((res) => {
        toastr.success(res.data.message, 'Patidar Sagai');
        history.push('/auth/otp-request');
      })
      .catch((error) => {
        formik.setStatus(error.join('<br/>'));
      });
  };

  return (
    <>
      {/*begin::Signin*/}
      <div className="login-form login-signin">
        {/*begin::Form*/}
        <form
          // onSubmit={formik.handleSubmit}
          className="form"
          id="kt_login_signin_form"
        >
          {/*begin::Title*/}
          <div className="pb-13 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              {t('login.TITLE')}
            </h3>
            <span className="text-muted font-weight-bold font-size-h4">
              {t('login.NEW_HERE')}?{' '}
              <Link
                to="/auth/registration"
                id="kt_login_signup"
                className="text-primary font-weight-bolder"
              >
                {t('login.CREATE_AN_ACCOUNT')}
              </Link>
            </span>
          </div>
          {/*end::Title*/}
          {formik.status && (
            <div
              className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
              style={{ display: 'block' }}
            >
              <div
                className="alert-text font-weight-bold"
                dangerouslySetInnerHTML={{ __html: formik.status }}
              ></div>
            </div>
          )}
          {/*begin::Form group*/}
          <div className="form-group">
            <label className="font-size-h6 font-weight-bolder text-dark">
              {t('login.EMAIL_PHONE_NUMBER')}
            </label>
            <input
              autoFocus
              className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg ${getInputClasses(
                'phoneNumber'
              )}`}
              type="text"
              name="phoneNumber"
              autoComplete="off"
              placeholder={t('login.EMAIL_PHONE_NUMBER')}
              {...formik.getFieldProps('phoneNumber')}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.phoneNumber}</div>
              </div>
            ) : null}
          </div>
          {/*end::Form group*/}
          {/*begin::Form group*/}
          <div className="form-group">
            <div className="d-flex justify-content-between mt-n5">
              <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                {t('login.PASSWORD')}
              </label>
              <Link
                to="/auth/forgot-password"
                className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                id="kt_login_forgot"
              >
                {t('login.FORGOT_PASSWORD')} ?
              </Link>
            </div>
            <input
              className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg ${getInputClasses(
                'password'
              )}`}
              type="password"
              name="password"
              placeholder={t('login.PASSWORD')}
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/*end::Form group*/}
          {/*begin::Action*/}
          <div className="pb-lg-0 pb-5">
            <button
              type="button"
              id="kt_login_signin_submit"
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              disabled={formik.isSubmitting}
              onClick={() => {
                // enableLoading();
                formik.setSubmitting(true);
                reCaptchaObj.current.reset();
                reCaptchaObj.current.execute();
              }}
            >
              <span>{t('login.SIGN_IN')}</span>
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <button
              type="button"
              id="kt_login_signin_submit"
              className="btn btn-success font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              onClick={SendOtpRequest}
              disabled={!formik.values.phoneNumber || loading}
            >
              {t('login.REQUEST_OTP')}
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          {/*end::Action*/}
          {/* <div className="text-center pb-lg-0 pb-5">
            <label className="font-size-h6 font-weight-bolder text-dark pt-5">
              Or
            </label>
          </div> */}
          {/*begin::Action*/}
          {/* <div className="pb-lg-0 pb-5">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder px-4 py-4 my-3 font-size-lg mr-2"
            >
              <span className="svg-icon svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"
                    fill="#34A853"
                  />
                  <path
                    d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"
                    fill="#EB4335"
                  />
                </svg>
              </span>
              Sign in with Google
            </button>
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder px-4 py-4 my-3 font-size-lg"
            >
              <span className="svg-icon svg-icon-md">
                <svg
                  enableBackground="new 0 0 24 24"
                  height={20}
                  viewBox="0 0 24 24"
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-6.932 0-5.046 7.85-5.322 9h-3.487v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877c.188-2.824-.761-5.016 2.051-5.016z"
                    fill="#3b5999"
                  />
                </svg>
              </span>
              Sign in with Facebook
            </button>
          </div> */}
          <Recaptcha
            ref={reCaptchaObj}
            sitekey="6LcpVDsaAAAAANOyJmqw0Z3jfOCP9vNtcmyzD6N5"
            onResolved={() => {
              const captchaResp = reCaptchaObj.current.getResponse();
              formik.setFieldValue('captchaResponse', captchaResp);
              formik.handleSubmit();
            }}
          />
          {/*end::Action*/}
        </form>
        {/*end::Form*/}
      </div>
      {/*end::Signin*/}
    </>
  );
}

export default Login;
