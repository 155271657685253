import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import * as Yup from 'yup';
import { postSubscribe } from '../../../../api';
import { showSwal } from '../../../../constants/Common';
import { toAbsoluteUrl } from '../../../_helpers';

export function FooterExtended({ today, footerClasses, footerContainerClasses }) {
  const [t] = useTranslation('Footer');
  const bgStyle = toAbsoluteUrl('/media/bg/bg-2.jpg');

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .max(30, t('SUBSCRIBE.VALIDATION.EMAIL_MAX'))
        .email(t('SUBSCRIBE.VALIDATION.EMAIL_VALID'))
        .required(t('SUBSCRIBE.VALIDATION.EMAIL_REQUIRED')),
    }),
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      postSubscribe(values.email)
        .then(() => {
          resetForm();
          setSubmitting(false);
          toastr.success('You are subscribe successfully!');
        })
        .catch((error) => {
          setSubmitting(false);
          showSwal(error);
        });
    },
  });

  return (
    <>
      {/* begin::Footer */}
      <div
        className={`footer ${footerClasses} kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover`}
        id="kt_footer"
        style={{ backgroundImage: `url("${bgStyle}")` }}
      >
        {/* begin::Container */}
        <div className={`${footerContainerClasses} py-lg-18 py-8`}>
          {/* begin::Row */}
          <div className="row">
            {/* begin::Col */}
            <div className="col-lg-4 my-lg-">
              <h4 className="text-white pb-3">{t('ABOUT')}</h4>
              <p className="m-0 text-white opacity-45">{t('ABOUT_DESC')}</p>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col-lg-4 my-lg-0 my-5">
              <h4 className="text-white pb-3">{t('QUICK_LINKS')}</h4>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column mr-18">
                  <Link to="/about" className="py-2 text-white opacity-55 text-hover-primary">
                    {t('MENU.ABOUT')}
                  </Link>
                  <Link to="/contact" className="py-2 text-white opacity-55 text-hover-primary">
                    {t('MENU.CONTACT')}
                  </Link>
                  <Link to="/terms" className="py-2 text-white opacity-55 text-hover-primary">
                    {t('MENU.TERMS_CONDITIONS')}
                  </Link>
                  <Link
                    to="/privacy-policy"
                    className="py-2 text-white opacity-55 text-hover-primary"
                  >
                    {t('MENU.PRIVACY_POLICY')}
                  </Link>
                </div>
                {/* <div className="d-flex flex-column">
                  <Link
                    to="/events"
                    className="text-white opacity-55 text-hover-primary"
                  >
                    {t("MENU.EVENTS")}
                  </Link>
                  <Link
                    to="/gallery"
                    className="py-2 text-white opacity-55 text-hover-primary"
                  >
                    {t("MENU.GALLERY")}
                  </Link>
                  <Link
                    to="/news"
                    className="text-white opacity-50 text-hover-primary"
                  >
                    {t("MENU.NEWS")}
                  </Link>
                </div> */}
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col-lg-4 my-lg-0 my-5">
              <h4 className="text-white pb-3">{t('SUBSCRIBE.GET_IN_TOUCH')}</h4>
              <form
                className="rounded"
                style={{ backgroundColor: 'rgba(0,0,0,.2)' }}
                onSubmit={formik.handleSubmit}
              >
                <div className="input-group p-2 align-items-center">
                  <input
                    type="text"
                    className={`form-control rounded-right border-0 bg-transparent text-white opacity-80 ${getInputClasses(
                      'email'
                    )}`}
                    autoComplete="off"
                    placeholder={t('SUBSCRIBE.EMAIL_PH')}
                    {...formik.getFieldProps('email')}
                  />
                  <div className="input-group-append p-0 mr-1">
                    <button
                      type="submit"
                      className="btn btn-fh btn-sm  btn-primary px-6 rounded-left"
                      disabled={formik.isSubmitting}
                    >
                      {t('SUBSCRIBE.JOIN')}
                      {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                  </div>
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </form>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Container */}

        {/* begin::Container */}
        <div className="separator separator-solid opacity-7"></div>
        {/* end::Container */}

        {/* begin::Container */}
        <div className={`${footerContainerClasses} py-8`}>
          <div className="d-flex align-items-center justify-content-between flex-lg-row flex-column">
            {/* begin::Copyright */}
            <div className="d-flex align-items-center order-lg-1 order-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl('/media/logos/patidar-sagai-pink-light.png')}
                className="logo-sticky max-h-35px mr-2"
              />
              <span className="text-muted font-weight-bold mx-2">2019-{today} ©</span>
              <Link to="/" className="text-primary text-hover-primary">
                {t('PATIDAR_SAGAI')}
              </Link>
            </div>
            {/* end::Copyright */}

            {/* begin::Nav */}
            <div className="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
              <a
                href="https://www.facebook.com/patidarsagai"
                target="_blank"
                rel="noreferrer"
                className="btn btn-icon btn-circle btn-light-facebook mr-2"
              >
                <i className="socicon-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/patidarsagai"
                target="_blank"
                rel="noreferrer"
                className="btn btn-icon btn-circle btn-light-instagram mr-2"
              >
                <i className="socicon-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/patidarsagai"
                target="_blank"
                rel="noreferrer"
                className="btn btn-icon btn-circle btn-light-linkedin mr-2"
              >
                <i className="socicon-linkedin"></i>
              </a>
              <a
                href="https://twitter.com/patidarsagai"
                target="_blank"
                rel="noreferrer"
                className="btn btn-icon btn-circle btn-light-twitter mr-2"
              >
                <i className="socicon-twitter"></i>
              </a>
            </div>
            {/* end::Nav */}
          </div>
        </div>
        {/* end::Container */}
      </div>
      {/* end:Footer */}
    </>
  );
}
