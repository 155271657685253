import axios from "axios";

export function getNotification(filterValue) {
  return axios.post(`${process.env.REACT_APP_API_URL}/Notification`, filterValue);
}

export function getUnreadNotification() {
  return axios.get(`${process.env.REACT_APP_API_URL}/Notification/Unread`);
}

export function getNewNotification() {
  return axios.get(`${process.env.REACT_APP_API_URL}/Notification/New`);
}

export function getPushNotification() {
  return axios.get(`${process.env.REACT_APP_API_URL}/Notification/Push`);
}

export function getHistory(filterValue) {
  return axios.post(`${process.env.REACT_APP_API_URL}/History`, filterValue);
}

export function getLoginHistory() {
  return axios.get(`${process.env.REACT_APP_API_URL}/Account/LoginHistory`);
}

export function acceptConnectRequest(values) {
  return axios.post(`${process.env.REACT_APP_API_URL}/Match/AcceptedByUserId`, values);
}

export function declineConnectRequest(values) {
  return axios.post(`${process.env.REACT_APP_API_URL}/Match/DeclinedByUserId`, values);
}

export function getPopupSetting() {
  return axios.get(`${process.env.REACT_APP_API_URL}/PopupSetting/Web`);
}
