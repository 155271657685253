/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from '../_patidarsagai/layout';
import BasePage from './BasePage';
import { AuthPage } from './modules/Auth';
import ErrorsPage from './modules/Errors/ErrorsPage';
// import Home from './modules/Landing/pages/Home/Home';
// import About from './modules/Landing/pages/About';
// import Gallery from './modules/Landing/pages/Gallery';
// import News from './modules/Landing/pages/News';
// import Events from './modules/Landing/pages/Events';
// import Contact from './modules/Landing/pages/Contact';
// import { TermsAndConditions } from './modules/Landing/pages/TermsAndConditions';
// import { PrivacyPolicy } from './modules/Landing/pages/PrivacyPolicy';

export function Routes() {
  const { emailConfirmed } = useSelector(
    ({ auth }) => ({
      emailConfirmed: (auth.user && auth.user.emailConfirmed) || false,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!emailConfirmed ? (
        <Route path="/auth" component={AuthPage} />
      ) : (
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/error" component={ErrorsPage} />

      {!emailConfirmed ? (
        <Redirect from="/" to="/auth/login" />
      ) : (
        // <LandingLayout>
        //   <Switch>
        //     <Route exact path="/" component={Home} />
        //     <Route exact path="/home" component={Home} />
        //     <Route exact path="/about" component={About} />
        //     <Route exact path="/gallery" component={Gallery} />
        //     <Route exact path="/news" component={News} />
        //     <Route exact path="/events" component={Events} />
        //     <Route exact path="/contact" component={Contact} />
        //     <Route path="/terms" component={TermsAndConditions} />
        //     <Route path="/privacy-policy" component={PrivacyPolicy} />
        //     <Redirect to="/" />
        //   </Switch>
        // </LandingLayout>
        <>
          <Layout>
            <BasePage />
          </Layout>
        </>
      )}
    </Switch>
  );
}
