import axios from 'axios';

export async function fetchTicketList(filterValue) {
  const params = {
    pageIndex: filterValue.pageIndex,
    pageSize: filterValue.pageSize,
    sortText: 't.Status asc, t.CreatedOn desc',
    searchText: '',
  };
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Ticket/Get`, params);
  return response.data;
}

//* Get
export async function getTicketById(ticketId) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Ticket/${ticketId}`);
  return response.data;
}

export async function postTicket(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Ticket/NewTicket`, values);
  return response.data;
}

//* Post
export async function sendMessage(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Ticket/TicketReply`, values);
  return response.data;
}
