import axios from 'axios';

export async function findFeatureProfile() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Home/GetFeatureProfile`);
  return response.data;
}

export async function postEnquiries(contactData) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Ticket/NewTicket`,
    contactData
  );
  return response.data;
}

export async function postSubscribe(email) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Home/Subscribe?emailAddress=${email}`
  );
  return response.data;
}

export async function getTopicListData() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Home/Name`);
  return response.data;
}
