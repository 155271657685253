import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import toastr from 'toastr';
import * as Yup from 'yup';
import * as actions from '../_redux/AuthActions';
import { ChangeEmailModal } from './ChangeEmailModal/ChangeEmailModal';

const otpRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function VerifyOtp(props) {
  const [t] = useTranslation('Auth');
  const [modalShow, setModalShow] = useState(false);
  const [ipLocData, setIPlocData] = useState({ ip: null, location: null, geoLocation: null });
  const dispatch = useDispatch();
  const { userName, emailConfirmed, fcmToken } = useSelector(
    ({ auth }) => ({
      userName: auth.userName || '',
      fcmToken: auth.fcmToken || '',
      emailConfirmed: (auth.user && auth.user.emailConfirmed) || false,
    }),
    shallowEqual
  );

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((data) => {
        setIPlocData({
          ip: data?.ip,
          geoLocation: `${data?.latitude},${data?.longitude}`,
          location: `${data?.city}, ${data?.region}, ${data?.country_name}:${data?.postal}`,
        });
      });
  }, []);

  const VerifyOtpSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(otpRegExp, t('verifyOtp.VALIDATION.OTP_VALID'))
      .length(6, t('verifyOtp.VALIDATION.OTP_DIGITS'))
      .required(t('verifyOtp.VALIDATION.OTP_REQUIRED')),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const initialValues = {
    otp: '',
    userName: userName,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: VerifyOtpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      dispatch(
        actions.verifyOTP(
          userName,
          values.otp,
          fcmToken,
          ipLocData.ip,
          ipLocData.location,
          ipLocData.geoLocation
        )
      )
        .then(() => {
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.join('<br/>'));
        });
    },
  });

  const ResendOTP = () => {
    formik.setStatus();
    dispatch(actions.sendOTP(userName))
      .then((res) => {
        toastr.success(res.data.message, 'Patidar Sagai');
      })
      .catch((error) => {
        formik.setStatus(error.join('<br/>'));
      });
  };

  return (
    <>
      {emailConfirmed || !userName ? (
        <Redirect to="/" />
      ) : (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form"
            noValidate="novalidate"
            id="kt_login_forgot_form"
          >
            {/*begin::Title*/}
            <div className="pb-13 pt-lg-0 pt-5">
              <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {t('verifyOtp.TITLE')} ?
              </h3>
              {/* <p className="text-muted font-weight-bold font-size-h4">
                {t('verifyOtp.SUB_TITLE')}
              </p> */}
              <p
                className="text-muted font-weight-bold font-size-h4"
                dangerouslySetInnerHTML={{ __html: t('verifyOtp.EMAIL_TITLE', { userName }) }}
              ></p>
            </div>
            {/*end::Title*/}
            {formik.status && (
              <div
                className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                style={{ display: 'block' }}
              >
                <div
                  className="alert-text font-weight-bold"
                  dangerouslySetInnerHTML={{ __html: formik.status }}
                ></div>
              </div>
            )}
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                autoFocus
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'otp'
                )}`}
                type="text"
                placeholder={t('verifyOtp.OTP')}
                name="otp"
                autoComplete="off"
                {...formik.getFieldProps('otp')}
              />
              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
              <span className="form-text text-muted">
                {t('verifyOtp.RESEND_TITLE')}
                <a href="#verifyOtp" onClick={ResendOTP} className="font-weight-bold pl-1">
                  {t('verifyOtp.RESEND_CODE')}
                </a>
              </span>
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group d-flex flex-wrap pb-lg-0">
              <button
                type="submit"
                id="kt_login_forgot_submit"
                className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                disabled={formik.isSubmitting}
              >
                {t('verifyOtp.SUBMIT')}
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                >
                  {t('verifyOtp.CANCEL')}
                </button>
              </Link>
            </div>
            {/*end::Form group*/}
            {/*begin::Title*/}
            <div className="pb-13 pt-lg-0 pt-5">
              <p>{t('verifyOtp.NOTE')}</p>
              <span
                className="pr-1"
                dangerouslySetInnerHTML={{ __html: t('verifyOtp.CHANGE_EMAIL_TITLE') }}
              ></span>
              <a href="#verifyOtp" class="text-primary" onClick={() => setModalShow(true)}>
                {t('verifyOtp.CHANGE_EMAIL_ADDRESS')}
              </a>
            </div>
            {/*end::Title*/}
          </form>
          {/*end::Form*/}
        </div>
      )}
      {modalShow && <ChangeEmailModal show={modalShow} onHide={() => setModalShow(false)} />}
    </>
  );
}

export default VerifyOtp;
