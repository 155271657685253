import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as translationEN from './en';
import * as translationGU from './gu';

// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: translationEN,
  gu: translationGU,
};

const language = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'gu';
i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: language,
    resources,
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      transSupportBasicHtmlNodes: true,
      wait: true,
    },
  });

export default i18n;
