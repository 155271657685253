import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_patidarsagai/layout';
import { DashboardPage } from './pages/DashboardPage';
import { ProfileWizard } from './modules/ProfileWizard/pages/ProfileWizard';
import { Logout } from './modules/Auth';
import { shallowEqual, useSelector } from 'react-redux';

const MyProfilePage = lazy(() => import('./modules/MyProfile/pages/MyProfilePage.js'));
const Matches = lazy(() => import('./modules/Matches/pages/MatchesPage'));
const Search = lazy(() => import('./modules/Search/pages/search'));
const Inbox = lazy(() => import('./modules/Inbox/pages/inbox'));
const Ticket = lazy(() => import('./modules/Ticket/pages/Ticket'));
const Notification = lazy(() => import('./modules/Notification/pages/NotificationPage'));

export default function BasePage() {
  const { profileAccess } = useSelector(
    ({ auth }) => ({
      profileAccess: auth.user.profileStatus,
    }),
    shallowEqual
  );

  const profileRoute = (val) => {
    switch (val) {
      case 0:
        return (
          <>
            <Switch>
              <Route path="/logout" component={Logout} />
            </Switch>
            <ProfileWizard />
          </>
        );
      case 1:
        return (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/profile" />
            }
            <Route path="/profile" component={MyProfilePage} />
            <Route path="/notification" component={Notification} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/error/oops" />
          </Switch>
        );

      case 2:
        return (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/profile" />
            }
            <Route path="/profile" component={MyProfilePage} />
            <Route path="/notification" component={Notification} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/error/oops" />
          </Switch>
        );

      case 3:
        return (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/matches" />
            }
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <Route path="/profile" component={MyProfilePage} />
            <Route path="/matches" component={Matches} />
            <Route path="/search" component={Search} />
            <Route path="/inbox" component={Inbox} />
            <Route path="/ticket" component={Ticket} />
            <Route path="/notification" component={Notification} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/error/oops" />
          </Switch>
        );

      case 4:
        return (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/profile" />
            }
            <Route path="/profile" component={MyProfilePage} />
            <Route path="/notification" component={Notification} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/error/oops" />
          </Switch>
        );

      case 5:
        return (
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/profile" />
            }
            <Route path="/profile" component={MyProfilePage} />
            <Route path="/notification" component={Notification} />
            <Route path="/logout" component={Logout} />
            <Redirect to="/error/oops" />
          </Switch>
        );

      default:
        break;
    }
  };
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>{profileRoute(profileAccess)}</Suspense>
    </>
  );
}
