import * as requestFromServer from "./AuthCrud";
import { AuthSlice, callTypes } from "./AuthSlice";

const { actions } = AuthSlice;
export const login = (username, password, captchaResponse, fcmToken, ip, location, geoLocation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .login(username, password, captchaResponse, fcmToken, ip, location, geoLocation)
    .then((response) => {
      if (response.data.user.emailConfirmed) {
        dispatch(actions.login(response.data));
      } else {
        dispatch(actions.setUserName(response.data.user.email));
      }
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const logout = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .logout(data)
    .then(() => {
      dispatch(actions.logout());
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const refresh = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .refresh(data)
    .then((response) => {
      if (response.status === 200) {
        dispatch(actions.refresh(response.data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const register = (firstName, lastName, emailId, phoneNumber, countryCode, password, captchaResponse) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .register(firstName, lastName, emailId, phoneNumber, countryCode, password, captchaResponse)
    .then((response) => {
      if (!response.data.emailConfirmed) {
        dispatch(actions.setUserName(response.data.email));
      }
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const sendRequestOTP = (userName) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendRequestOTP(userName)
    .then((response) => {
      dispatch(actions.setUserName(response.data.email));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const verifyRequestOTP = (Email, OTP, FcmToken, ip, location, geoLocation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyRequestOTP(Email, OTP, FcmToken, ip, location, geoLocation)
    .then((response) => {
      dispatch(actions.login(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const changeEmailAddressOTP = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changeEmailAddressOTP(data)
    .then((response) => {
      dispatch(actions.setUserName(response.data.email));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const sendOTP = (userName) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendOTP(userName)
    .then((response) => {
      dispatch(actions.setUserName(response.data.email));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const verifyOTP = (Email, OTP, FcmToken, ip, location, geoLocation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyOTP(Email, OTP, FcmToken, ip, location, geoLocation)
    .then((response) => {
      dispatch(actions.login(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .forgotPassword(email)
    .then((response) => {
      dispatch(actions.setUserName(response.data.email));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const verifyForgotPassword = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .verifyForgotPassword(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const fetchCountryList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCountryList()
    .then((response) => {
      dispatch(actions.countryListFetched(response.data));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const getProfileStatus = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProfileStatus()
    .then((response) => {
      dispatch(actions.setProfileStatus(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
