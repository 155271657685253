import axios from 'axios';

export async function getMemberDetails(Id) {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Match/MemberDetails/${Id}`);
  return response.data;
}

export async function getNewMemberList(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/New`, values);
  return response.data;
}

export async function getAllMemberList(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match`, values);
  return response.data;
}

export async function getMemberListByPartnerPreferences(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/Get`, values);
  return response.data;
}

export async function sendConnectRequest(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/sendConnectRequest`,
    values
  );
  return response.data;
}

export async function blockMember(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/BlockMember`, values);
  return response.data;
}

export async function cancelConnectRequest(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/CancelConnectRequest`,
    values
  );
  return response.data;
}

export async function unblockMember(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/UnblockMember`, values);
  return response.data;
}

export async function addToShortList(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/AddMemberToShortlist`,
    values
  );
  return response.data;
}

export async function removeFromShortList(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/RemoveMemberFromShortlist`,
    values
  );
  return response.data;
}

export async function acceptConnectRequest(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/AcceptConnectRequest`,
    values
  );
  return response.data;
}

export async function declineConnectRequest(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/DeclineConnectRequest`,
    values
  );
  return response.data;
}

export async function getMemberShortList(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Profile/ShortListed`, values);
  return response.data;
}

export async function getMemberMatchedList(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/MatchedList`, values);
  return response.data;
}

export async function getMemberRecentList(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/RecentList`, values);
  return response.data;
}

export async function reportUserToAdmin(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/ReportUserToAdmin`,
    values
  );
  return response.data;
}

export async function searchMatch(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Match/Search`, values);
  return response.data;
}
