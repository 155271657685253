import Swal from 'sweetalert2/dist/sweetalert2.js';

export const showSwal = (error) => {
  Swal.fire({
    heightAuto: false,
    icon: 'error',
    title: 'Oops...',
    text: error,
  });
};
