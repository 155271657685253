const config = {
  SupportEmail: 'info@patidarsagai.com',
  SupportMobile: '+91 82002 47885',
  TermsUrl: 'http://patidarsagai.com',
  NoProfileImageUrl: 'http://me.patidarsagai.com/media/profile/noimage.png',
  LogoIconUrl: 'http://me.patidarsagai.com/media/logos/patidar-sagai-icon.png',
  MaximumConnectLimit: 5,
  MaximumBlockLimit: 20,
};

export default config;
