import React, { useEffect, useMemo } from 'react';
import objectPath from 'object-path';
import toastr from 'toastr';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// LayoutContext
import { useHtmlClassService } from '../_core/PatidarVivahLayout';
// Import Layout components
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import { SubHeader } from './subheader/SubHeader';
import { QuickUser } from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
import { AnimateLoading } from '../../_partials/controls';
import * as actions from '../../../app/modules/Notification/_redux/NotificationActions';
import * as authActions from '../../../app/modules/Auth/_redux/AuthActions';
import { AuthSlice } from '../../../app/modules/Auth/_redux/AuthSlice';
import { toAbsoluteUrl } from '../../../_patidarsagai/_helpers';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { onMessageListener } from '../../../firebase';

export function Layout({ children }) {
  document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/bg/bg-7.jpg')})`;
  document.body.style.backgroundSize = 'cover';
  document.body.style.backgroundAttachment = 'fixed';
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      userId: auth.user.userId,
      profileStatus: auth.user.profileStatus,
    }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    uiService.config.footer.layout = 'compact';
    uiService.config.extras.notifications.display = true;
    uiService.config.extras.search.display = true;
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.data.notifyType && event.data.data.notifyType === 'Logout') {
        dispatch(AuthSlice.actions.logout());
      } else {
        dispatch(AuthSlice.actions.setProfileStatus(event.data.data.profileStatus));
        history.push('/');
      }
    });
    // signalRConnection();
    dispatch(actions.getPopupSetting())
      .then((resp) => {
        resp.data &&
          Swal.fire({
            icon: 'warning',
            title: resp.data.title,
            html: resp.data.body,
            confirmButtonText: resp.data.buttonText,
          }).then((result) => {
            if (resp.data.buttonAction && result.isConfirmed) {
              window.open(resp.data.buttonAction, '_blank');
            }
          });
      })
      .catch((error) => showSwal(error));
    dispatch(actions.fetchNewNotification()).catch((error) => showSwal(error));
    // if (profileStatus === 1) {
    dispatch(authActions.getProfileStatus()).catch((error) => showSwal(error));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessageListener()
    .then((payload) => {
      if (payload.data.notifyType && payload.data.notifyType === 'Logout') {
        dispatch(AuthSlice.actions.logout());
        console.log('Notification Service: Data IF Logout', payload.data);
      } else {
        console.log('Notification Service: Data IF', payload.data);
        dispatch(AuthSlice.actions.setProfileStatus(payload.data.profileStatus));
        dispatch(actions.fetchNewNotification()).catch((error) => showSwal(error));
        history.push('/');
      }
      if (payload.notification) {
        toastr.success(payload.notification.body, payload.notification.title);
      }
    })
    .catch((err) => console.log('failed: ', err));
  // const signalRConnection = () => {
  //   let hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${process.env.REACT_APP_API_URL}/patidarHub`)
  //     .build();

  //   // push notification
  //   let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  //   navigator.serviceWorker.register(swUrl).then((response) => {
  //     return response.pushManager.getSubscription().then(function (subscription) {
  //       response.pushManager.subscribe({
  //         userVisibleOnly: true,
  //         applicationServerKey: determineAppServerKey(),
  //       });
  //       hubConnection.on('getPushNotification', (user_id) => {
  //         if (userId === user_id) {
  //           dispatch(actions.fetchPushNotification()).then((resp) => {
  //             const notifyData = resp.data;
  //             if (notifyData.notifyType === 'ProfileConnected' ||
  //               notifyData.notifyType === 'ProfileAccepted' ||
  //               notifyData.notifyType === 'ProfileCorrection' ||
  //               notifyData.notifyType === 'ProfileApproved') {
  //               toastr.success(notifyData.description, 'Patidar Sagai');
  //             }
  //             if (Notification.permission === 'granted') {
  //               response.showNotification("Patidar Sagai", {
  //                 icon: Config.LogoIconUrl,
  //                 body: notifyData.description,
  //               });
  //             }
  //           }).catch((error) => showSwal(error));
  //         }
  //       });
  //     });
  //   });

  //   hubConnection.on('getNewNotification', (user_id) => {
  //     if (userId === user_id) {
  //       dispatch(actions.fetchNewNotification())
  //         .catch((error) => showSwal(error));
  //     }
  //   });
  //   hubConnection.on('getProfileStatus', (user_id) => {
  //     if (userId === user_id) {
  //       dispatch(authActions.getProfileStatus())
  //         .catch((error) => showSwal(error));
  //     }
  //   });
  //   hubConnection.start().then(() => console.info('Now connected'))
  //     .catch(() => console.error('Could not connect'));
  // }

  // function determineAppServerKey() {
  //   const vapidPublicKey =
  //     'BJthRQ5myDgc7OSXzPCMftGw-n16F7zQBEN7EUD6XxcfTTvrLGWSIG7y_JxiWtVlCFua0S8MTB5rPziBqNx1qIo';
  //   return urlBase64ToUint8Array(vapidPublicKey);
  // }

  // function urlBase64ToUint8Array(base64String) {
  //   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  //   const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  //   const rawData = window.atob(base64);
  //   const outputArray = new Uint8Array(rawData.length);

  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  //   return outputArray;
  // }

  const showSwal = (error) => {
    Swal.fire({
      heightAuto: false,
      icon: 'error',
      title: 'Oops...',
      text: error,
    });
  };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      <AnimateLoading />

      {/*begin::Main*/}
      <HeaderMobile />

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {isAuthorized ? layoutProps.subheaderDisplay && <SubHeader /> : null}
              {/*begin::Entry*/}
              {layoutProps.contentExtended && (
                <>
                  {layoutProps.asideDisplay && (
                    <div className="d-lg-flex flex-row-fluid">
                      <Aside />
                      <div className="content-wrapper">{children}</div>
                    </div>
                  )}

                  {!layoutProps.asideDisplay && <>{children}</>}
                </>
              )}

              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {layoutProps.asideDisplay && (
                      <div className="d-lg-flex flex-row-fluid">
                        <Aside />
                        <div className="content-wrapper flex-row-fluid">{children}</div>
                      </div>
                    )}
                    {!layoutProps.asideDisplay && <>{children}</>}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
