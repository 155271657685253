/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as _redux from './redux';
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './_patidarsagai/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_patidarsagai/_assets/plugins/flaticon/flaticon.css';
import './_patidarsagai/_assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import {
  PatidarVivahLayoutProvider,
  PatidarVivahSplashScreenProvider,
  PatidarVivahSubheaderProvider,
} from './_patidarsagai/layout';
import { I18nextProvider } from 'react-i18next';
import i18n from './_patidarsagai/translations/i18n';
import 'sweetalert2/src/sweetalert2.scss';
import 'nouislider/distribute/nouislider.css';
import toastr from 'toastr';

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic PatidarSagai mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ _redux.mockAxios(axios);

/**
 * Inject patidarsagai interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <PatidarVivahLayoutProvider>
      <PatidarVivahSubheaderProvider>
        <PatidarVivahSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </PatidarVivahSplashScreenProvider>
      </PatidarVivahSubheaderProvider>
    </PatidarVivahLayoutProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
