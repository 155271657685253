import { useFormik } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import toastr from 'toastr';
import * as Yup from 'yup';
import * as actions from '../../_redux/AuthActions';

export function ChangeEmailModal(props) {
  const [t] = useTranslation('Auth');
  const dispatch = useDispatch();
  const { userName } = useSelector(
    ({ auth }) => ({
      userName: auth.userName || '',
    }),
    shallowEqual
  );

  const ChangeEmailSchema = Yup.object().shape({
    userName: Yup.string()
      .email(t('Change_Email_Modal.VALIDATION.EMAIL_ADDRESS_VALID'))
      .max(256, t('Change_Email_Modal.VALIDATION.EMAIL_ADDRESS_MAX'))
      .required(t('Change_Email_Modal.VALIDATION.EMAIL_ADDRESS_REQUIRED')),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: userName,
    },
    validationSchema: ChangeEmailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const data = {
        emailAddress: userName,
        newEmailAddress: values.userName,
      };
      dispatch(actions.changeEmailAddressOTP(data))
        .then((res) => {
          toastr.success(res.data.message, 'Patidar Sagai');
          setSubmitting(false);
          props.onHide();
        })
        .catch((error) => {
          setSubmitting(false);
          showSwal(error);
        });
    },
  });

  const showSwal = (error) => {
    Swal.fire({
      heightAuto: false,
      icon: 'error',
      title: 'Oops...',
      text: error,
    });
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <form onSubmit={formik.handleSubmit} id="kt_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('Change_Email_Modal.CHANGE_EMAIL_ADDRESS')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <input
              autoFocus
              className={`form-control form-control-solid h-auto py-5 px-5 rounded-lg font-size-h6 ${getInputClasses(
                'userName'
              )}`}
              type="text"
              placeholder={t('Change_Email_Modal.CORRECT_EMAIL_ADDRESS')}
              name="userName"
              autoComplete="off"
              {...formik.getFieldProps('userName')}
            />
            {formik.touched.userName && formik.errors.userName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.userName}</div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => props.onHide()}>
            {t('Change_Email_Modal.CLOSE')}
          </button>
          <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
            {t('Change_Email_Modal.SUBMIT')}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
