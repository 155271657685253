import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import toastr from 'toastr';
import * as Yup from 'yup';
import * as actions from '../_redux/AuthActions';
import { useTranslation } from 'react-i18next';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const [t] = useTranslation('Auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required(t('forgotPassword.VALIDATION.EMAIL_REQUIRED')),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      dispatch(actions.forgotPassword(values.email))
        .then((res) => {
          setSubmitting(false);
          toastr.success(res.data.message, 'Patidar Sagai');
          history.push('/auth/new-password');
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.join('<br/>'));
        });
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate="novalidate"
          id="kt_login_forgot_form"
        >
          {/*begin::Title*/}
          <div className="pb-13 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              {t('forgotPassword.TITLE')} ?
            </h3>
            <p className="text-muted font-weight-bold font-size-h4">
              {t('forgotPassword.SUB_TITLE')}
            </p>
          </div>
          {/*end::Title*/}
          {formik.status && (
            <div
              className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
              style={{ display: 'block' }}
            >
              <div
                className="alert-text font-weight-bold"
                dangerouslySetInnerHTML={{ __html: formik.status }}
              ></div>
            </div>
          )}
          {/*begin::Form group*/}
          <div className="form-group">
            <input
              autoFocus
              className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                'email'
              )}`}
              type="email"
              placeholder={t('forgotPassword.EMAIL')}
              name="email"
              autoComplete="off"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          {/*end::Form group*/}
          {/*begin::Form group*/}
          <div className="form-group d-flex flex-wrap pb-lg-0">
            <button
              type="submit"
              id="kt_login_forgot_submit"
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
              disabled={formik.isSubmitting}
            >
              {t('forgotPassword.SUBMIT')}
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              >
                {t('forgotPassword.CANCEL')}
              </button>
            </Link>
          </div>
          {/*end::Form group*/}
        </form>
        {/*end::Form*/}
      </div>
    </>
  );
}

export default ForgotPassword;
