/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from '../app/Routes';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_patidarsagai/layout';
import { getTokens } from './../firebase';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export default function App({ store, persistor, basename }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getTokens();
      store.dispatch({
        type: 'Auth/setFcmToken',
        payload: data,
      });
    }
    tokenFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    /* Provide Redux store */
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
