/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { useTranslation } from 'react-i18next';

const languages = [
  {
    lang: 'gu',
    name: 'Gujarati',
    flag: toAbsoluteUrl('/media/svg/flags/010-india.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/svg/flags/226-united-states.svg'),
  },
];

export function LanguageSelectorDropdown() {
  const { t, i18n } = useTranslation('HeaderMenu');
  const getslanguage =
    localStorage.getItem('i18nextLng') === 'en-US' ? 'en' : localStorage.getItem('i18nextLng');
  const [useLang, setLanguage] = useState(getslanguage);
  const lang = useLang;
  const currentLanguage = languages.find((x) => x.lang === lang);
  const changeLanguage = (event) => {
    i18n.changeLanguage(event);
    setLanguage(event);
    window.location.reload();
  };
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="language-panel-tooltip">{t('SELECT_LANGUAGE')}</Tooltip>}
        >
          <div className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
            <img
              className="h-20px w-20px rounded-sm"
              src={currentLanguage.flag}
              alt={currentLanguage.name}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <ul className="navi navi-hover py-4">
          {languages.map((language) => (
            <Dropdown.Item
              key={language.lang}
              className={clsx('navi-item', {
                active: language.lang === currentLanguage.lang,
              })}
              onClick={() => changeLanguage(language.lang)}
            >
              <div className="navi-link">
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </div>
            </Dropdown.Item>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
