import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  getAnnualIncome,
  getCountryList,
  getOccupationList,
  getUser,
  getVillageList,
  postLifestyleDetailsData,
} from '../../../../../api';
import { showSwal } from '../../../../../constants/Common';
import { familyType, noOfBrother } from '../../../../../constants/List';
import KTWizard from '../../../../../_patidarsagai/_assets/js/components/wizard';

export default function LifestyleDetails() {
  const [t] = useTranslation('ProfileWizard');
  const [loading, setLoading] = useState(false);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const RegistrationSchema = Yup.object().shape({
    fatherName: Yup.string()
      .max(30, t('lifestyle_family.VALIDATION.FATHER_NAME_MAX'))
      .required(t('lifestyle_family.VALIDATION.FATHER_NAME_REQUIRED')),
    fatherOccupationId: Yup.string().required(
      t('lifestyle_family.VALIDATION.FATHER_OCCUPATION_IS_REQUIRED')
    ),
    noOfBrother: Yup.string().required(t('lifestyle_family.VALIDATION.BROTHER_REQUIRED')),
    noOfSister: Yup.string().required(t('lifestyle_family.VALIDATION.SISTER_REQUIRED')),
    nativePlace: Yup.string().required(t('lifestyle_family.VALIDATION.NATIVE_PLACE_REQUIRED')),
    parentPhone1: Yup.string()
      .matches(phoneRegExp, t('lifestyle_family.VALIDATION.PARENT_PHONE1_NUMBER_VALID'))
      .required(t('lifestyle_family.VALIDATION.PARENT_PHONE1_NUMBER_REQUIRED')),
    parentPhone1Code: Yup.string().required(
      t('lifestyle_family.VALIDATION.PARENT_PHONE1_CODE_REQUIRED')
    ),
    parentPhone1IsWhatApps: Yup.bool(),
    parentPhone2: Yup.string().matches(
      phoneRegExp,
      t('lifestyle_family.VALIDATION.PARENT_PHONE2_NUMBER_VALID')
    ),
    parentPhone2Code: Yup.string().required(
      t('lifestyle_family.VALIDATION.PARENT_PHONE2_CODE_REQUIRED')
    ),
    nativeDistrict: Yup.string().required(
      t('lifestyle_family.VALIDATION.NATIVE_DISTRICT_REQUIRED')
    ),
    nativeTaluko: Yup.string().required(t('lifestyle_family.VALIDATION.NATIVE_TALUKO_REQUIRED')),
    parentPhone2IsWhatApps: Yup.bool(),
  });

  const countryListQuery = useQuery({
    queryKey: ['countryListQuery'],
    queryFn: () => getCountryList().catch((error) => showSwal(error)),
  });

  const annualIncomeListQuery = useQuery({
    queryKey: ['annualIncomeListQuery'],
    queryFn: () => getAnnualIncome().catch((error) => showSwal(error)),
  });
  const villageListQuery = useQuery({
    queryKey: ['villageListQuery'],
    queryFn: () => getVillageList().catch((error) => showSwal(error)),
  });
  const occupationListQuery = useQuery({
    queryKey: ['occupationListQuery'],
    queryFn: () => getOccupationList().catch((error) => showSwal(error)),
  });

  const memberDetailQuery = useQuery({
    queryKey: ['memberDetailQuery'],
    queryFn: () => getUser().catch((error) => showSwal(error)),
  });

  const getInputClasses = (fieldname) => {
    if (formikLifestyle.touched[fieldname] && formikLifestyle.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formikLifestyle.touched[fieldname] && !formikLifestyle.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formikLifestyle = useFormik({
    enableReinitialize: true,
    initialValues: {
      fatherName: memberDetailQuery.data?.fatherName || '',
      fatherOccupationId: memberDetailQuery.data?.fatherOccupationId || '',
      motherName: memberDetailQuery.data?.motherName || '',
      motherOccupationId: memberDetailQuery.data?.motherOccupationId || null,
      familyType: memberDetailQuery.data?.familyType || '',
      familyAnnualIncomeId: memberDetailQuery.data?.familyAnnualIncomeId || null,
      noOfBrother: memberDetailQuery.data?.noOfBrother || 0,
      noOfSister: memberDetailQuery.data?.noOfSister || 0,
      nativePlace: memberDetailQuery.data?.nativePlace || '',
      nativeDistrict: memberDetailQuery.data?.nativeDistrict || '',
      nativeTaluko: memberDetailQuery.data?.nativeTaluko || '',
      mosad: memberDetailQuery.data?.mosad || '',
      parentPhone1: memberDetailQuery.data?.parentPhone1 || '',
      parentPhone1Code: memberDetailQuery.data?.parentPhone1Code || '+91',
      parentPhone1IsWhatApps:
        memberDetailQuery.data && memberDetailQuery.data?.parentPhone1IsWhatApps === null
          ? false
          : memberDetailQuery.data && memberDetailQuery.data?.parentPhone1IsWhatApps,
      parentPhone2: memberDetailQuery.data?.parentPhone2 || '',
      parentPhone2Code: memberDetailQuery.data?.parentPhone2Code || '+91',
      parentPhone2IsWhatApps:
        memberDetailQuery.data && memberDetailQuery.data?.parentPhone2IsWhatApps === null
          ? false
          : memberDetailQuery.data && memberDetailQuery.data?.parentPhone2IsWhatApps,
    },
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      values['id'] = memberDetailQuery.data?.id;
      values['familyAnnualIncomeId'] = values.familyAnnualIncomeId
        ? values.familyAnnualIncomeId
        : null;
      values['motherOccupationId'] = values.motherOccupationId ? values.motherOccupationId : null;
      postLifestyleDetailsData(values)
        .then(() => {
          const wizard = new KTWizard('kt_wizard');
          wizard.goTo(3);
          setSubmitting(false);
          setLoading(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setLoading(false);
          showSwal(error);
        });
    },
  });

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content">
        <form onSubmit={formikLifestyle.handleSubmit} id="kt_form_lifestyle">
          <div className="row">
            {/* ########################## Fathers Name ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.FATHERS_NAME')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'fatherName'
                  )}`}
                  name="fatherName"
                  placeholder={t('lifestyle_family.FATHERS_NAME_PH')}
                  {...formikLifestyle.getFieldProps('fatherName')}
                />
                {formikLifestyle.touched.fatherName && formikLifestyle.errors.fatherName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.fatherName}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Fathers Occupation ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.FATHERS_OCCUPATION')}</label>
                <select
                  name="fatherOccupationId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'fatherOccupationId'
                  )}`}
                  {...formikLifestyle.getFieldProps('fatherOccupationId')}
                >
                  <option value="">Select</option>
                  {occupationListQuery.data
                    ?.filter((oct) => oct.occupationType.includes('3'))
                    .map((list) => {
                      return (
                        <option key={list.id} value={list.id}>
                          {list.name}
                        </option>
                      );
                    }) || []}
                </select>
                {formikLifestyle.touched.fatherOccupationId &&
                formikLifestyle.errors.fatherOccupationId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.fatherOccupationId}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Mothers Name ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.MOTHERS_NAME')}</label>
                <input
                  type="text"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'motherName'
                  )}`}
                  {...formikLifestyle.getFieldProps('motherName')}
                  name="motherName"
                  placeholder={t('lifestyle_family.MOTHERS_NAME_PH')}
                />
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Mothers Occupation ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.MOTHERS_OCCUPATION')}</label>
                <select
                  name="motherOccupationId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'motherOccupationId'
                  )}`}
                  {...formikLifestyle.getFieldProps('motherOccupationId')}
                >
                  <option value="">Select</option>
                  {occupationListQuery.data
                    ?.filter((oct) => oct.occupationType.includes('2'))
                    .map((list) => {
                      return (
                        <option key={list.id} value={list.id}>
                          {list.name}
                        </option>
                      );
                    }) || []}
                </select>
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Family Type ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.FAMILY_TYPE')}</label>
                <select
                  name="familyType"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'familyType'
                  )}`}
                  {...formikLifestyle.getFieldProps('familyType')}
                >
                  <option value="">Select</option>
                  {familyType.map((list) => {
                    return (
                      <option key={list.id} value={list.value}>
                        {list.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Family Annual Income ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.FAMILY_ANNUAL_INCOME')}</label>
                <select
                  name="familyAnnualIncomeId"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'familyAnnualIncomeId'
                  )}`}
                  {...formikLifestyle.getFieldProps('familyAnnualIncomeId')}
                >
                  <option value="">Select</option>
                  {annualIncomeListQuery.data?.map((list) => {
                    return (
                      <option key={list.id} value={list.id}>
                        {list.name}
                      </option>
                    );
                  }) || []}
                </select>
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Brother ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>
                  {t('lifestyle_family.BROTHER')}{' '}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{t('lifestyle_family.BROTHER_NOTE')}</Tooltip>}
                  >
                    <i className="flaticon-questions-circular-button text-warning"></i>
                  </OverlayTrigger>
                </label>
                <select
                  name="noOfBrother"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'noOfBrother'
                  )}`}
                  {...formikLifestyle.getFieldProps('noOfBrother')}
                >
                  {/* <option value="">Select</option> */}
                  {noOfBrother.map((list) => {
                    return (
                      <option key={list.id} value={list.value}>
                        {list.name}
                      </option>
                    );
                  }) || []}
                </select>
                {formikLifestyle.touched.noOfBrother && formikLifestyle.errors.noOfBrother ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.noOfBrother}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Sister ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>
                  {t('lifestyle_family.SISTER')}{' '}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{t('lifestyle_family.SISTER_NOTE')}</Tooltip>}
                  >
                    <i className="flaticon-questions-circular-button text-warning"></i>
                  </OverlayTrigger>
                </label>
                <select
                  name="noOfSister"
                  className={`form-control form-control-solid form-control-lg ${getInputClasses(
                    'noOfSister'
                  )}`}
                  {...formikLifestyle.getFieldProps('noOfSister')}
                >
                  {/* <option value="">Select</option> */}
                  {noOfBrother.map((list) => {
                    return (
                      <option key={list.id} value={list.value}>
                        {list.name}
                      </option>
                    );
                  }) || []}
                </select>
                {formikLifestyle.touched.noOfSister && formikLifestyle.errors.noOfSister ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.noOfSister}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Native Place ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.NATIVE_PLACE')}</label>
                <Typeahead
                  selected={
                    villageListQuery.data?.filter(
                      (c) => c.name === formikLifestyle.values.nativePlace
                    ).length > 0
                      ? villageListQuery.data?.filter(
                          (c) => c.name === formikLifestyle.values.nativePlace
                        )
                      : [{ id: 0, name: formikLifestyle.values.nativePlace }]
                  }
                  onChange={(e) => {
                    e.length && e.map((c) => formikLifestyle.setFieldValue('nativePlace', c.name));
                  }}
                  onInputChange={(input, e) => formikLifestyle.setFieldValue('nativePlace', input)}
                  inputProps={{
                    className: `form-control-solid-bg ${getInputClasses('nativePlace')}`,
                  }}
                  size="lg"
                  id="nativeTypeahead"
                  labelKey="name"
                  options={villageListQuery.data || []}
                  placeholder={t('lifestyle_family.NATIVE_PLACE_PH')}
                />
                {formikLifestyle.touched.nativePlace && formikLifestyle.errors.nativePlace ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.nativePlace}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.NATIVE_DISTRICT')}</label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${getInputClasses('nativeDistrict')}`}
                  name="nativeDistrict"
                  placeholder={t('lifestyle_family.NATIVE_DISTRICT')}
                  {...formikLifestyle.getFieldProps('nativeDistrict')}
                />
                {formikLifestyle.touched.nativeDistrict && formikLifestyle.errors.nativeDistrict ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.nativeDistrict}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            <div className="col-xl-4">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.NATIVE_TALUKO')}</label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${getInputClasses('nativeTaluko')}`}
                  name="nativeTaluko"
                  placeholder={t('lifestyle_family.NATIVE_TALUKO')}
                  {...formikLifestyle.getFieldProps('nativeTaluko')}
                />
                {formikLifestyle.touched.nativeTaluko && formikLifestyle.errors.nativeTaluko ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.nativeTaluko}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Mosad ########################## */}
            <div className="col-xl-6">
              {/*begin::Input*/}
              <div className="form-group">
                <label>{t('lifestyle_family.MOSAD')}</label>
                <Typeahead
                  selected={
                    villageListQuery.data?.filter((c) => c.name === formikLifestyle.values.mosad)
                      .length > 0
                      ? villageListQuery.data?.filter(
                          (c) => c.name === formikLifestyle.values.mosad
                        )
                      : [{ id: 0, name: formikLifestyle.values.mosad }]
                  }
                  onInputChange={(input, e) => formikLifestyle.setFieldValue('mosad', input)}
                  onChange={(e) => {
                    e.length && e.map((c) => formikLifestyle.setFieldValue('mosad', c.name));
                  }}
                  inputProps={{
                    className: `form-control-solid-bg ${getInputClasses('mosad')}`,
                  }}
                  id="mosadTypeahead"
                  size="lg"
                  labelKey="name"
                  options={villageListQuery.data || []}
                  placeholder={t('lifestyle_family.MOSAD_PH')}
                />
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Parents phone ########################## */}
            <div className="col-xl-8">
              {/*begin::Input*/}
              <div className="form-group">
                <label>
                  {t('lifestyle_family.PARENTS_PHONE_1')}&nbsp;
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{t('lifestyle_family.PARENTS_PHONE_1_NOTE')}</Tooltip>}
                  >
                    <i className="flaticon-questions-circular-button text-warning"></i>
                  </OverlayTrigger>
                </label>
                <div className="input-group">
                  <select
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'parentPhone1Code'
                    )}`}
                    style={{ padding: 0 }}
                    name="parentPhone1Code"
                    {...formikLifestyle.getFieldProps('parentPhone1Code')}
                  >
                    {countryListQuery.data?.map((list) => {
                      return (
                        <option key={list.id} value={list.code}>
                          {list.code} ({list.shortName})
                        </option>
                      );
                    }) || []}
                  </select>
                  {/* <div className="input-group-prepend">
                    <span className="input-group-text">+91</span>
                  </div> */}
                  <input
                    type="text"
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'parentPhone1'
                    )}`}
                    aria-label="Text input with checkbox"
                    name="parentPhone1"
                    placeholder={t('lifestyle_family.PARENTS_PHONE_1_PH')}
                    {...formikLifestyle.getFieldProps('parentPhone1')}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <label className="checkbox checkbox-outline checkbox-success">
                        <input
                          type="checkbox"
                          name="parentPhone1IsWhatApps"
                          onChange={(e) =>
                            formikLifestyle.setFieldValue(
                              'parentPhone1IsWhatApps',
                              e.target.checked
                            )
                          }
                          checked={
                            formikLifestyle && formikLifestyle.values.parentPhone1IsWhatApps === ''
                              ? false
                              : (formikLifestyle &&
                                  formikLifestyle.values.parentPhone1IsWhatApps) ||
                                false
                          }
                        />
                        <span />
                      </label>
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>{t('lifestyle_family.PARENTS_PHONE_1_WHATSAPP')}</Tooltip>}
                    >
                      <span className="input-group-text">
                        <i className="flaticon-whatsapp text-success"></i>
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>

                {formikLifestyle.touched.parentPhone1 && formikLifestyle.errors.parentPhone1 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formikLifestyle.errors.parentPhone1}</div>
                  </div>
                ) : null}
              </div>
              {/*end::Input*/}
            </div>

            {/* ########################## Parents phone ########################## */}
            {/* <div className="col-xl-6">
              <div className="form-group">
                <label>{t("lifestyle_family.PARENTS_PHONE_2")}</label>
                <div className="input-group">
                  <select
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      'parentPhone2Code'
                    )}`}
                    style={{padding: 0}}
                    name="parentPhone2Code"
                    {...formikLifestyle.getFieldProps('parentPhone2Code')}
                  >
                    {countryList.map((list) => {
                      return (
                        <option key={list.id} value={list.code}>
                          {list.code} ({list.shortName})
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="text"
                    className={`form-control form-control-solid form-control-lg ${getInputClasses(
                      "parentPhone2"
                    )}`}
                    aria-label="Text input with checkbox"
                    name="parentPhone2"
                    placeholder={t("lifestyle_family.PARENTS_PHONE_2_PH")}
                    {...formikLifestyle.getFieldProps("parentPhone2")}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <label className="checkbox checkbox-outline checkbox-success">
                        <input
                          type="checkbox"
                          name="parentPhone2IsWhatApps"
                          onChange={(e) =>
                            formikLifestyle.setFieldValue(
                              "parentPhone2IsWhatApps",
                              e.target.checked
                            )
                          }
                          checked={
                            formikLifestyle &&
                            formikLifestyle.values.parentPhone2IsWhatApps === ""
                              ? false
                              : (formikLifestyle &&
                                  formikLifestyle.values.parentPhone2IsWhatApps) ||
                                false
                          }
                        />
                        <span />
                      </label>
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>
                          Check if whatsapp is active on this number
                        </Tooltip>
                      }
                    >
                      <span className="input-group-text">
                        <i className="flaticon-whatsapp text-success"></i>
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>

                {formikLifestyle.touched.parentPhone2 && formikLifestyle.errors.parentPhone2 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formikLifestyle.errors.parentPhone2}
                    </div>
                  </div>
                ) : null}
              </div>
            </div> */}
          </div>
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div className="mr-2">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-prev"
              >
                Previous
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                disabled={loading}
              >
                <span>Save & Continue</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
