import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import '../../../../_patidarsagai/_assets/sass/pages/login/login-1.scss';
import { toAbsoluteUrl } from '../../../../_patidarsagai/_helpers';
import { ContentRoute } from '../../../../_patidarsagai/layout';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import NewPassword from './NewPassword';
import OtpRequest from './OtpRequest';
import Registration from './Registration';
import VerifyOtp from './VerifyOtp';

export function AuthPage() {
  const [t] = useTranslation('Auth');
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-column flex-row-auto"
            style={{ backgroundColor: '#F2C98A' }}
          >
            {/*begin::Aside Top*/}
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
              {/*begin::Aside header*/}
              <a href="http://patidarsagai.com" className="text-center mb-10">
                <img
                  alt="Logo"
                  className="max-h-60px"
                  src={toAbsoluteUrl('/media/logos/patidar-sagai-red.png')}
                />
              </a>
              {/*end::Aside header*/}
              {/*begin::Aside title*/}
              <h3
                className="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
                style={{ color: '#986923' }}
              >
                {t('auth.DESCRIPTION_1')}
                <br />
                {t('auth.DESCRIPTION_2')}
              </h3>
              {/*end::Aside title*/}
            </div>
            {/*end::Aside Top*/}
            {/*begin::Aside Bottom*/}
            <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/img/w2.png')})`,
              }}
            />
            {/*end::Aside Bottom*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            {/*begin::Content body*/}
            <div className="d-flex flex-column-fluid flex-center">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/registration" component={Registration} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                <ContentRoute path="/auth/new-password" component={NewPassword} />
                <ContentRoute path="/auth/otp-request" component={OtpRequest} />
                <ContentRoute path="/auth/verify-otp" component={VerifyOtp} />
                {/* <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" /> */}
              </Switch>
            </div>
            {/*end::Content body*/}
            {/*begin::Content footer*/}
            <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
              <div className="text-dark-50 font-size-lg font-weight-bolder mr-10">
                <span className="mr-1">2019-{currentYear} ©</span>
                <a
                  href="http://patidarsagai.com"
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                  rel="noreferrer"
                >
                  {t('auth.PATIDAR_SAGAI')}
                </a>
              </div>
              <a
                href="http://patidarsagai.com/terms"
                target="_blank"
                className="text-primary font-weight-bolder font-size-lg"
                rel="noreferrer"
              >
                {t('auth.TERMS')}
              </a>
              <a
                href="http://patidarsagai.com/contact"
                target="_blank"
                className="text-primary ml-5 font-weight-bolder font-size-lg"
                rel="noreferrer"
              >
                {t('auth.CONTACT')}
              </a>
            </div>
            {/*end::Content footer*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
