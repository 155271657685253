import clsx from 'clsx';
import { useFormik } from 'formik';
import objectPath from 'object-path';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import toastr from 'toastr';
import * as Yup from 'yup';
import { searchMatch } from '../../../../../../api';
import { showSwal } from '../../../../../../constants/Common';
import { toAbsoluteUrl } from '../../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../../_partials/dropdowns';
import { useHtmlClassService } from '../../../../_core/PatidarVivahLayout';

export function SearchDropdown() {
  const { t } = useTranslation('HeaderMenu');
  const { user } = useSelector((state) => ({ user: state.auth.user }));

  const SearchSchema = Yup.object().shape({
    searchText: Yup.string().required(t('QUICK_SEARCH_REQUIRED')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchText: '',
    },
    validationSchema: SearchSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      searchMatch({ profileId: values.searchText, memberId: user.memberId })
        .then((resp) => {
          if (resp > 0) window.location.href = '/matches/detail/' + resp;
          else {
            toastr.warning('Profile not found...', 'Patidar Sagai');
            resetForm();
          }
        })
        .catch((error) => showSwal(error));
    },
  });

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.search.layout') === 'offcanvas',
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_search_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown alignRight drop="down" onToggle={formik.resetForm} id="kt_quick_search_toggle">
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="search-panel-tooltip">{t('QUICK_SEARCH')}</Tooltip>}
            >
              <div className="btn btn-icon btn-hover-transparent-white btn-lg btn-dropdown mr-1">
                <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              id="kt_quick_search_dropdown"
              className={clsx('quick-search quick-search-dropdown', {
                'quick-search-has-result': 1,
              })}
            >
              <form className="quick-search-form" onSubmit={formik.handleSubmit}>
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')} />
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder={t('QUICK_SEARCH_PH')}
                    className="form-control"
                    {...formik.getFieldProps('searchText')}
                  />

                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i
                        style={{
                          display: 'flex',
                        }}
                        onClick={formik.resetForm}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
