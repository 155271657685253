import axios from 'axios';

export async function getMember() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/CreateProfile`);
  return response.data;
}

export async function postPersonalDetailsData(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/CreateProfile/PersonalDetails`,
    values
  );
  return response.data;
}

export async function postLifestyleDetailsData(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/CreateProfile/FamilyDetails`,
    values
  );
  return response.data;
}

export async function postProfessionalValueDetailsData(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/CreateProfile/ProfessionalDetails`,
    values
  );
  return response.data;
}

export async function postUploadPhotoDetailsData(values) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/CreateProfile/UploadPhoto`,
    values
  );
  return response.data;
}
