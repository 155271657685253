import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_patidarsagai/_helpers';
import '../../../../_patidarsagai/_assets/sass/pages/wizard/wizard-1.scss';
import { useTranslation } from 'react-i18next';

import PersonalDetails from './PersonalDetails/PersonalDetails';
import LifestyleDetails from './LifestyleDetails/LifestyleDetails';
import ProfessionalDetails from './ProfessionalDetails/ProfessionalDetails';
import UploadPhoto from './UploadPhoto/UploadPhoto';

export function ProfileWizard() {
  const [t] = useTranslation('ProfileWizard');
  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="card-body p-0">
                {/*begin::Wizard*/}
                <div
                  className="wizard wizard-1"
                  id="kt_wizard"
                  data-wizard-state="step-first"
                  data-wizard-clickable="false"
                >
                  {/*begin::Wizard Nav*/}
                  <div className="wizard-nav border-bottom">
                    <div className="wizard-steps p-8 p-lg-10">
                      {/*begin::Wizard Step 1 Nav*/}
                      <div
                        className="wizard-step"
                        data-wizard-type="step"
                        data-wizard-state="current"
                      >
                        <div className="wizard-label">
                          <i className="wizard-icon flaticon2-file-1" />
                          <h3 className="wizard-title">1. {t('tab_title.PERSONAL_DETAILS')}</h3>
                        </div>
                        <span className="svg-icon svg-icon-xl wizard-arrow">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')} />
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Wizard Step 1 Nav*/}
                      {/*begin::Wizard Step 2 Nav*/}
                      <div className="wizard-step" data-wizard-type="step">
                        <div className="wizard-label">
                          <i className="wizard-icon flaticon-users" />
                          <h3 className="wizard-title">2. {t('tab_title.LIFESTYLE_FAMILY')}</h3>
                        </div>
                        <span className="svg-icon svg-icon-xl wizard-arrow">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')} />
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Wizard Step 2 Nav*/}
                      {/*begin::Wizard Step 3 Nav*/}
                      <div className="wizard-step" data-wizard-type="step">
                        <div className="wizard-label">
                          <i className="wizard-icon flaticon-suitcase" />
                          <h3 className="wizard-title">3. {t('tab_title.PROFESSIONAL_DETAILS')}</h3>
                        </div>
                        <span className="svg-icon svg-icon-xl wizard-arrow">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')} />
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Wizard Step 3 Nav*/}
                      {/*begin::Wizard Step 5 Nav*/}
                      <div className="wizard-step" data-wizard-type="step">
                        <div className="wizard-label">
                          <i className="wizard-icon flaticon-upload-1" />
                          <h3 className="wizard-title">4. {t('tab_title.UPLOAD_PHOTO')}</h3>
                        </div>
                        <span className="svg-icon svg-icon-xl wizard-arrow last">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')} />
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                      {/*end::Wizard Step 5 Nav*/}
                    </div>
                  </div>
                  {/*end::Wizard Nav*/}
                  {/*begin::Wizard Body*/}
                  <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div className="col-xl-12 col-xxl-7">
                      {/*begin::Wizard Form*/}
                      {/* <form className="form" id="kt_form"> */}
                      {/*begin::Wizard Step 1*/}
                      <PersonalDetails />
                      {/*end::Wizard Step 1*/}
                      {/*begin::Wizard Step 2*/}
                      <LifestyleDetails />
                      {/*end::Wizard Step 2*/}
                      {/*begin::Wizard Step 3*/}
                      <ProfessionalDetails />
                      {/*end::Wizard Step 3*/}
                      {/*begin::Wizard Step 4*/}
                      <UploadPhoto />
                      {/*end::Wizard Step 4*/}
                      {/* </form> */}
                      {/*end::Wizard Form*/}
                    </div>
                  </div>
                  {/*end::Wizard Body*/}
                </div>
                {/*end::Wizard*/}
              </div>
              {/*end::Wizard*/}
            </div>
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Entry*/}
      </div>
    </>
  );
}
