import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import toastr from 'toastr';
import * as Yup from 'yup';
import * as actions from '../_redux/AuthActions';

const otpRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function NewPassword() {
  const [t] = useTranslation('Auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const { userName } = useSelector(
    ({ auth }) => ({
      userName: auth.userName || '',
    }),
    shallowEqual
  );

  const initialValues = {
    email: userName,
    otp: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const NewPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('newPassword.VALIDATION.EMAIL_VALID'))
      .min(3, t('newPassword.VALIDATION.EMAIL_MIN'))
      .max(256, t('newPassword.VALIDATION.EMAIL_MAX'))
      .required(t('newPassword.VALIDATION.EMAIL_REQUIRED')),
    otp: Yup.string()
      .matches(otpRegExp, t('newPassword.VALIDATION.OTP_VALID'))
      .length(6, t('newPassword.VALIDATION.OTP_DIGITS'))
      .required(t('newPassword.VALIDATION.OTP_REQUIRED')),
    newPassword: Yup.string()
      .min(6, t('newPassword.VALIDATION.NEW_PASSWORD_MIN'))
      .max(100, t('newPassword.VALIDATION.NEW_PASSWORD_MAX'))
      .required(t('newPassword.VALIDATION.NEW_PASSWORD_REQUIRED')),
    confirmNewPassword: Yup.string()
      .required(t('newPassword.VALIDATION.CONFIRM_NEW_PASSWORD_REQUIRED'))
      .when('newPassword', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          t('newPassword.VALIDATION.NEW_PASSWORD_MATCH')
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      dispatch(
        actions.verifyForgotPassword({
          email: values.email,
          otp: values.otp,
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword,
        })
      )
        .then((res) => {
          setSubmitting(false);
          toastr.success(res.data, 'Patidar Sagai');
          history.push('/auth/login');
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(error.join('<br/>'));
        });
    },
  });

  const ResendOTP = () => {
    formik.setStatus();
    dispatch(actions.sendRequestOTP(userName))
      .then((res) => {
        toastr.success(res.data.message, 'Patidar Sagai');
      })
      .catch((error) => {
        formik.setStatus(error.join('<br/>'));
      });
  };

  return (
    <>
      {!userName ? (
        <Redirect to="/" />
      ) : (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form"
            noValidate="novalidate"
            id="kt_login_forgot_form"
          >
            {/*begin::Title*/}
            <div className="pb-13 pt-lg-0 pt-5">
              <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {t('newPassword.TITLE')} ?
              </h3>
              {/* <p className="text-muted font-weight-bold font-size-h4">
              {t('newPassword.SUB_TITLE')}
              </p> */}
              <p
                className="text-muted font-weight-bold font-size-h4"
                dangerouslySetInnerHTML={{ __html: t('newPassword.EMAIL_TITLE', { userName }) }}
              ></p>
            </div>
            {/*end::Title*/}
            {formik.status && (
              <div
                className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                style={{ display: 'block' }}
              >
                <div
                  className="alert-text font-weight-bold"
                  dangerouslySetInnerHTML={{ __html: formik.status }}
                ></div>
              </div>
            )}
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'email'
                )}`}
                type="email"
                placeholder={t('newPassword.EMAIL')}
                name="email"
                autoComplete="off"
                disabled={true}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
              <span className="form-text text-muted">
                {t('newPassword.RESEND_TITLE')}
                <a href="#newPassword" onClick={ResendOTP} className="font-weight-bold pl-1">
                  {t('newPassword.RESEND_CODE')}
                </a>
              </span>
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                autoFocus
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'otp'
                )}`}
                type="text"
                placeholder={t('newPassword.OTP')}
                name="otp"
                autoComplete="off"
                {...formik.getFieldProps('otp')}
              />
              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'newPassword'
                )}`}
                type="password"
                placeholder={t('newPassword.NEW_PASSWORD')}
                name="newPassword"
                autoComplete="off"
                {...formik.getFieldProps('newPassword')}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.newPassword}</div>
                </div>
              ) : null}
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group">
              <input
                className={`form-control form-control-solid h-auto py-6 px-6 rounded-lg font-size-h6 ${getInputClasses(
                  'confirmNewPassword'
                )}`}
                type="password"
                placeholder={t('newPassword.CONFIRM_NEW_PASSWORD')}
                name="confirmNewPassword"
                autoComplete="off"
                {...formik.getFieldProps('confirmNewPassword')}
              />
              {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
                </div>
              ) : null}
            </div>
            {/*end::Form group*/}
            {/*begin::Form group*/}
            <div className="form-group d-flex flex-wrap pb-lg-0">
              <button
                type="submit"
                id="kt_login_forgot_submit"
                className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                disabled={formik.isSubmitting}
              >
                {t('newPassword.SUBMIT')}
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                >
                  {t('newPassword.CANCEL')}
                </button>
              </Link>
            </div>
            {/*end::Form group*/}
          </form>
          {/*end::Form*/}
        </div>
      )}
    </>
  );
}

export default NewPassword;
