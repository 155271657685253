import { useFormik } from 'formik';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import toastr from 'toastr';
import * as Yup from 'yup';
import { postUploadPhotoDetailsData } from '../../../../../api';
import { showSwal } from '../../../../../constants/Common';
import KTWizard from '../../../../../_patidarsagai/_assets/js/components/wizard';
import { CropPhotoModal } from './CropPhotoModal/CropPhotoModal';

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const initialValues = {
  imageFiles: undefined,
};
export default function UploadPhoto() {
  const [t] = useTranslation('ProfileWizard');
  const RegistrationSchema = Yup.object().shape({
    imageFiles: Yup.array().min(1).required(t('profile_Photo.VALIDATION.IMAGE_REQUIRED')),
  });
  const [fileObj, setFileObj] = useState({ files: [], image: undefined });
  const [modalObj, setModalObj] = useState({ modalShow: false });

  const photoPreview = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const reader = new FileReader();
      reader.onload = () => {
        setFileObj({ files: acceptedFiles, image: reader.result });
      };
      reader.readAsDataURL(acceptedFiles[0]);
      showCropModal();
    } else {
      toastr.warning('Please select single image file to upload.', 'Upload Photo');
    }
  };

  const { user } = useSelector((state) => ({ user: state.auth.user }));

  const goToPrev = () => {
    const wizard = new KTWizard('kt_wizard');
    wizard.goPrev();
  };

  const formikUploadPhoto = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      if (fileObj.files.length > 0) {
        values['id'] = user && user.memberId;
        const form = new FormData();
        form.append('Id', values.id);
        form.append('ProfileImage', values.imageFiles[0]);
        postUploadPhotoDetailsData(form)
          .then(() => {
            resetForm();
            setSubmitting(false);
            setFileObj({ files: [], image: undefined });
            Swal.fire({
              icon: 'success',
              title: 'Profile Created!',
              html: `Congratulations ! <b>Your profile has been created successfully</b> and
            submit for approval, it will take approx 24 hours to process your
            request.<br/> We will review and notify you once it approved.
            <br />
            Thank You!`,
            });
          })
          .catch((error) => {
            setSubmitting(false);
            showSwal(error);
          });
      } else {
        setSubmitting(false);
      }
    },
  });

  const setCropData = (croppedImageDataURL) => {
    const byteArray = dataURLtoFile(croppedImageDataURL, fileObj.files[0].name);
    const formData = [];
    formData.push(
      Object.assign(byteArray, {
        preview: URL.createObjectURL(byteArray),
      })
    );
    formikUploadPhoto.setFieldValue('imageFiles', formData);
    setFileObj({ files: formData, image: undefined });
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const showCropModal = () => {
    setModalObj({ modalShow: true });
  };

  const hideCropModal = (flag) => {
    setModalObj({ modalShow: false });
    if (flag) {
      setFileObj({ files: [], image: undefined });
      formikUploadPhoto.resetForm();
    }
  };

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content">
        <form onSubmit={formikUploadPhoto.handleSubmit} id="kt_form_uploadphoto">
          <Dropzone
            maxFiles={1}
            accept="image/jpeg, image/png"
            onDrop={(acceptedFiles) => {
              photoPreview(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                style={formikUploadPhoto.errors.imageFiles ? { borderColor: 'red' } : {}}
                className="dropzone dropzone-default dropzone-primary"
                {...getRootProps()}
              >
                <div className="dropzone-msg dz-message needsclick">
                  {fileObj.files.length > 0 && (
                    <div style={thumb}>
                      <div style={thumbInner}>
                        <img src={fileObj.files[0].preview} style={img} alt="" />
                      </div>
                    </div>
                  )}
                  <h3 className="dropzone-msg-title">
                    <input {...getInputProps()} />

                    {fileObj.files.length === 0 ? (
                      <>
                        <span>{t('profile_Photo.DROP_IMAGE_TITLE')}</span>
                        <br />
                        <em>({t('profile_Photo.IMAGE_ACCEPT')})</em>
                      </>
                    ) : (
                      ''
                    )}
                  </h3>
                  {/* <span className="dropzone-msg-desc">Upload up to 10 files</span> */}
                </div>
              </div>
            )}
          </Dropzone>
          {formikUploadPhoto.errors.imageFiles ? (
            <p className="text-danger">{t('profile_Photo.VALIDATION.IMAGE_REQUIRED')}</p>
          ) : (
            ''
          )}
          <div className="mt-5">
            <div className="d-flex justify-content-between align-items-center mb-7">
              <span className="font-size-h7 mb-0">
                <i className="fas fa-check text-success"></i> {t('profile_Photo.RULE_1')} <br />
                <i className="fas fa-check text-success"></i> {t('profile_Photo.RULE_2')} <br />
                <i className="fas fa-check text-success"></i> {t('profile_Photo.RULE_3')} <br />
                <i className="fas fa-check text-success"></i> {t('profile_Photo.RULE_4')} <br />
                <i className="fas fa-check text-success"></i> {t('profile_Photo.RULE_5')} <br />
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div className="mr-2">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                data-wizard-type="action-prev"
                onClick={() => goToPrev()}
              >
                Previous
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                disabled={formikUploadPhoto.isSubmitting}
              >
                Submit
                {formikUploadPhoto.isSubmitting && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {modalObj.modalShow && (
        <CropPhotoModal
          show={modalObj.modalShow}
          image={fileObj.image}
          onHide={hideCropModal}
          onCrop={setCropData}
        />
      )}
    </>
  );
}
