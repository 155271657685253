import axios from 'axios';

export async function sentInbox(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inbox/SentList`, values);
  return response.data;
}

export async function receivedInbox(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inbox/ReceivedList`, values);
  return response.data;
}

export async function acceptedInbox(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inbox/AcceptedList`, values);
  return response.data;
}

export async function declinedInbox(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inbox/DeclinedList`, values);
  return response.data;
}

export async function blockedInbox(values) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Inbox/BlockedList`, values);
  return response.data;
}
