import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBE2KY6pOwcb2p4t845RT1_1DcBJr2j8hA',
  authDomain: 'patidar-vivah-9e60a.firebaseapp.com',
  projectId: 'patidar-vivah-9e60a',
  storageBucket: 'patidar-vivah-9e60a.appspot.com',
  messagingSenderId: '780450160596',
  appId: '1:780450160596:web:3614824516c26ec29cc041',
  measurementId: 'G-PYSYER3FFH',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokens = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        'BImhoGGf2yepZaHy3A7Yv2jlyKsiNTH_m4X_kM2r8n2kDILfVaz7tNM_Z69drzPIVNGhxYSrgIwL4dwybq76_KM',
    });
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // NotificationService(payload.data);
      resolve(payload);
    });
  });
