export const maritalStatus = [
  {
    id: 1,
    name: 'Never Married - અપરિણીત',
    value: 'Never Married - અપરિણીત',
    isSelected: false,
  },
  { id: 2, name: 'Widowed - વિધવા/વિધુર', value: 'Widowed - વિધવા/વિધુર', isSelected: false },
  { id: 3, name: 'Divorced - છૂટાછેડા', value: 'Divorced - છૂટાછેડા', isSelected: false },
  {
    id: 4,
    name: 'Awaiting Divorce - છૂટાછેડા બાકી',
    value: 'Awaiting Divorce - છૂટાછેડા બાકી',
    isSelected: false,
  },
];

// export const disability = [
//   {id: 1, name: 'Normal- સામાન્ય', value: 'Normal'},
//   {id: 2, name: 'શારીરિક રીતે પડકારરૂપ', value: 'Physically Challenged'},
// ];

export const bloodGroup = [
  { id: 1, name: 'A+', value: 'A+' },
  { id: 2, name: 'A-', value: 'A-' },
  { id: 3, name: 'B+', value: 'B+' },
  { id: 4, name: 'B-', value: 'B-' },
  { id: 5, name: 'O+', value: 'O+' },
  { id: 6, name: 'O-', value: 'O-' },
  { id: 7, name: 'AB+', value: 'AB+' },
  { id: 8, name: 'AB-', value: 'AB-' },
];

export const sunSign = [
  { id: 1, name: 'Aries - મેષ', value: 'Aries - મેષ' },
  { id: 2, name: 'Taurus - વૃષભ', value: 'Taurus - વૃષભ' },
  { id: 3, name: 'Gemini - મિથુન', value: 'Gemini - મિથુન' },
  { id: 4, name: 'Cancer - કર્ક', value: 'Cancer - કર્ક' },
  { id: 5, name: 'Leo - સિંહ', value: 'Leo - સિંહ' },
  { id: 6, name: 'Virgo - કન્યા', value: 'Virgo - કન્યા' },
  { id: 7, name: 'Libra - તુલા', value: 'Libra - તુલા' },
  { id: 8, name: 'Scorpio - વૃશ્ચિક', value: 'Scorpio - વૃશ્ચિક' },
  { id: 9, name: 'Sagittarius - ધન', value: 'Sagittarius - ધન' },
  { id: 10, name: 'Capricorn - મકર', value: 'Capricorn - મકર' },
  { id: 11, name: 'Aquarius - કુંભ', value: 'Aquarius - કુંભ' },
  { id: 12, name: 'Pisces - મીન', value: 'Pisces - મીન' },
];

export const mainCommunityList = [
  { id: 1, name: 'Kadva Patidar - કડવા પાટીદાર' },
  { id: 2, name: 'Leva Patidar - લેવા પાટીદાર' },
];

export const Languages = [
  'Hindi',
  'English',
  'Bengali',
  'Marathi',
  'Telugu',
  'Tamil',
  'Gujarati',
  'Urdu',
  'Kannada',
  'Odia',
  'Malayalam',
  'Punjabi',
  'Assamese',
  'Maithili',
  'Sanskrit',
];

export const Hobbies = [
  'Acting',
  'Animal breeding',
  'Art / Handicraft',
  'Astrology / Palmistry / Numerology',
  'Astronomy',
  'Bird watching',
  'Collectibles',
  'Cooking',
  'Dancing',
  'DIY(do it yourself) projects',
  'Film-making',
  'Fishing',
  'Gardening / Landscaping',
  'Graphology',
  'Ham radio',
  'Home / Interior decoration',
  'Hunting',
  'Model building',
  'Painting / Drawing',
  'Photography / Videography',
  'Playing musical instruments',
  'Singing',
  'Solving Crosswords / Puzzles / Playing Cards',
  'Sports & Adventure',
  'Arts & Entertainment',
  'Rock Sports',
  'Reading',
  'Writing',
  'Digital Design and Coding',
  'Meditation and Yoga',
  'Traveling',
  'Movie Watching',
  'Fashion Design',
  'Boxing',
  'Model Crafting',
  'Internet Marketing',
  'Singing',
  'Scrapbooking / Journaling',
];

export const height = [
  { id: 1, name: '4ft 5in - 134cm', value: '4ft 5in - 134cm' },
  { id: 2, name: '4ft 6in - 137cm', value: '4ft 6in - 137cm' },
  { id: 3, name: '4ft 7in - 139cm', value: '4ft 7in - 139cm' },
  { id: 4, name: '4ft 8in - 142cm', value: '4ft 8in - 142cm' },
  { id: 5, name: '4ft 9in - 144cm', value: '4ft 9in - 144cm' },
  { id: 6, name: '4ft 10in - 147cm', value: '4ft 10in - 147cm' },
  { id: 7, name: '4ft 11in - 149cm', value: '4ft 11in - 149cm' },
  { id: 8, name: '5ft 152cm', value: '5ft 152cm' },
  { id: 9, name: '5ft 1in - 154cm', value: '5ft 1in - 154cm' },
  { id: 10, name: '5ft 2in - 157cm', value: '5ft 2in - 157cm' },
  { id: 11, name: '5ft 3in - 160cm', value: '5ft 3in - 160cm' },
  { id: 12, name: '5ft 4in - 162cm', value: '5ft 4in - 162cm' },
  { id: 13, name: '5ft 5in - 165cm', value: '5ft 5in - 165cm' },
  { id: 14, name: '5ft 6in - 167cm', value: '5ft 6in - 167cm' },
  { id: 15, name: '5ft 7in - 170cm', value: '5ft 7in - 170cm' },
  { id: 16, name: '5ft 8in - 172cm', value: '5ft 8in - 172cm' },
  { id: 17, name: '5ft 9in - 175cm', value: '5ft 9in - 175cm' },
  { id: 18, name: '5ft 10in - 177cm', value: '5ft 10in - 177cm' },
  { id: 19, name: '5ft 11in - 180cm', value: '5ft 11in - 180cm' },
  { id: 20, name: '6ft  182cm', value: '6ft  182cm' },
  { id: 21, name: '6ft 1in - 185cm', value: '6ft 1in - 185cm' },
  { id: 22, name: '6ft 2in - 187cm', value: '6ft 2in - 187cm' },
  { id: 23, name: '6ft 3in - 190cm', value: '6ft 3in - 190cm' },
  { id: 24, name: '6ft 4in - 193cm', value: '6ft 4in - 193cm' },
  { id: 25, name: '6ft 5in - 195cm', value: '6ft 5in - 195cm' },
  { id: 26, name: '6ft 6in - 198cm', value: '6ft 6in - 198cm' },
  { id: 27, name: '6ft 7in - 200cm', value: '6ft 7in - 200cm' },
  { id: 28, name: '6ft 8in - 203cm', value: '6ft 8in - 203cm' },
  { id: 29, name: '6ft 9in - 205cm', value: '6ft 9in - 205cm' },
  { id: 30, name: '6ft 10in - 208cm', value: '6ft 10in - 208cm' },
  { id: 31, name: '6ft 11in - 210cm', value: '6ft 11in - 210cm' },
  { id: 33, name: '7ft  213cm', value: '7ft  213cm' },
];

export const familyType = [
  { id: 1, name: 'Joint - સંયુક્ત', value: 'Joint - સંયુક્ત' },
  { id: 2, name: 'Nuclear - વિભક્ત', value: 'Nuclear - વિભક્ત' },
];

export const noOfBrother = [
  { id: 1, name: '0', value: '0' },
  { id: 2, name: '1', value: '1' },
  { id: 3, name: '2', value: '2' },
  { id: 4, name: '3', value: '3' },
  { id: 5, name: '4', value: '4' },
  { id: 6, name: '5', value: '5' },
  { id: 7, name: '6', value: '6' },
  { id: 8, name: '7', value: '7' },
  { id: 9, name: '8', value: '8' },
  { id: 10, name: '9', value: '9' },
];

export const heightList = [
  '4ft 5in 134cm',
  '4ft 6in 137cm',
  '4ft 7in 139cm',
  '4ft 8in 142cm',
  '4ft 9in 144cm',
  '4ft 10in 147cm',
  '4ft 11in 149cm',
  '5ft 152cm',
  '5ft 1in 154cm',
  '5ft 2in 157cm',
  '5ft 3in 160cm',
  '5ft 4in 162cm',
  '5ft 5in 165cm',
  '5ft 6in 167cm',
  '5ft 7in 170cm',
  '5ft 8in 172cm',
  '5ft 9in 175cm',
  '5ft 10in 177cm',
  '5ft 11in 180cm',
  '6ft  182cm',
  '6ft 1in 185cm',
  '6ft 2in 187cm',
  '6ft 3in 190cm',
  '6ft 4in 193cm',
  '6ft 5in 195cm',
  '6ft 6in 198cm',
  '6ft 7in 200cm',
  '6ft 8in 203cm',
  '6ft 9in 205cm',
  '6ft 10in 208cm',
  '6ft 11in 210cm',
  '7ft  213cm',
];

export const heightenCM = [
  134,
  137,
  139,
  142,
  144,
  147,
  149,
  152,
  154,
  157,
  160,
  162,
  165,
  167,
  170,
  172,
  175,
  177,
  180,
  182,
  185,
  185,
  190,
  193,
  195,
  198,
  200,
  203,
  205,
  208,
  210,
  213,
];

export const annualIncomeWord = [
  '₹ 0 Lakh',
  '₹ 1 Lakh',
  '₹ 2 Lakh',
  '₹ 3 Lakh',
  '₹ 4 Lakh',
  '₹ 5 Lakh',
  '₹ 6 Lakh',
  '₹ 7 Lakh',
  '₹ 8 Lakh',
  '₹ 9 Lakh',
  '₹ 10 Lakh',
  '₹ 15 Lakh',
  '₹ 20 Lakh',
  '₹ 25 Lakh',
  '₹ 30 Lakh',
  '₹ 35 Lakh',
  '₹ 40 Lakh',
  '₹ 45 Lakh',
  '₹ 50 Lakh',
  '₹ 55 Lakh',
  '₹ 60 Lakh',
  '₹ 65 Lakh',
  '₹ 70 Lakh',
  '₹ 75 Lakh',
  '₹ 80 Lakh',
  '₹ 85 Lakh',
  '₹ 90 Lakh',
  '₹ 95 Lakh',
  '₹ 1 Cr & Above',
];

export const annualIncomeNumber = [
  0,
  100000,
  200000,
  300000,
  400000,
  500000,
  600000,
  700000,
  800000,
  900000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  5500000,
  6000000,
  6500000,
  7000000,
  7500000,
  8000000,
  8500000,
  9000000,
  9500000,
  100000000,
];

export const visaType = [
  { id: 1, name: 'Citizenship' },
  { id: 2, name: 'Permanent Resident' },
  { id: 3, name: 'Work Permit Visa' },
  { id: 4, name: 'Study Permit Visa' },
  { id: 5, name: 'Tourist Visa' },
  { id: 6, name: 'Business Visa' },
];
