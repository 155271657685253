import axios from 'axios';

export async function getNotification(filterValue) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/Notification`, filterValue);
  return response.data;
}

export async function getUnreadNotification() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Notification/Unread`);
  return response.data;
}

export async function getNewNotification() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Notification/New`);
  return response.data;
}

export async function getPushNotification() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Notification/Push`);
  return response.data;
}

export async function getHistory(filterValue) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/History`, filterValue);
  return response.data;
}

export async function getLoginHistory() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/Account/LoginHistory`);
  return response.data;
}

export async function acceptConnectRequestData(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/AcceptConnectRequestByUserId`,
    values
  );
  return response.data;
}

export async function declineConnectRequestData(values) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/Match/DeclineConnectRequestByUserId`,
    values
  );
  return response.data;
}

export async function getPopupSetting() {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/PopupSetting/Web`);
  return response.data;
}
