import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
  listLoading: false,
  actionsLoading: false,
  historyList: {
    result: [],
    totalCount: 0,
  },
  loginHistoryList: [],
  notificationList: {
    result: [],
    totalCount: 0,
  },
  unreadNotificationList: [],
  newNotificationList: [],
  notificationFilter: null,
  historyFilter: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const NotificationSlice = createSlice({
  name: "Notification",
  initialState: initialNotificationState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // Fetched Notification List
    notificationFetched: (state, action) => {
      const notificationList = action.payload;
      state.listLoading = false;
      if (notificationList.result.length && state.notificationList.result.length) {
        state.notificationList.result.push(...notificationList.result);
      } else {
        state.notificationList.result = notificationList.result;
      }
      state.notificationList.totalCount = notificationList.totalCount;
      state.newNotificationList = [];
    },

    // Unmount notification List
    notificationListUnmount: (state, action) => {
      state.listLoading = false;
      state.notificationList.result = [];
      state.notificationList.totalCount = 0;
    },

    // Set Notification Filter
    notificationFilterSet: (state, action) => {
      const filterValue = action.payload;
      state.notificationFilter = filterValue;
    },

    // Fetched Unread Notification List
    unreadNotificationFetched: (state, action) => {
      const unreadNotificationList = action.payload;
      state.listLoading = false;
      state.unreadNotificationList = unreadNotificationList;
    },

    // Fetched New Notification List
    newNotificationFetched: (state, action) => {
      const newNotificationList = action.payload;
      state.listLoading = false;
      state.newNotificationList = newNotificationList;
    },

    // Fetched History List
    historyFetched: (state, action) => {
      const historyList = action.payload;
      state.listLoading = false;
      if (historyList.result.length && state.historyList.result.length) {
        state.historyList.result.push(...historyList.result);
      } else {
        state.historyList.result = historyList.result;
      }
      state.historyList.totalCount = historyList.totalCount;
    },

    // Unmount history List
    historyListUnmount: (state, action) => {
      state.listLoading = false;
      state.historyList.result = [];
      state.historyList.totalCount = 0;
    },

    // Set history Filter
    historyFilterSet: (state, action) => {
      const filterValue = action.payload;
      state.historyFilter = filterValue;
    },

    // Fetched Login History List
    loginHistoryFetched: (state, action) => {
      const loginHistoryList = action.payload;
      state.listLoading = false;
      state.loginHistoryList = loginHistoryList;
    },

    // Accept Connect Request
    acceptConnectRequest: (state, action) => {
      const notificationObj = action.payload;
      state.actionsLoading = false;
      state.notificationList.result = state.notificationList.result.map((item) => {
        if (item.id === notificationObj.id) return notificationObj;
        else return item;
      });
    },

    // Decline Connect Request
    declineConnectRequest: (state, action) => {
      const notificationObj = action.payload;
      state.actionsLoading = false;
      state.notificationList.result = state.notificationList.result.map((item) => {
        if (item.id === notificationObj.id) return notificationObj;
        else return item;
      });
    },
  },
});
