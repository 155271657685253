import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import 'cropperjs/dist/cropper.css';

export function CropPhotoModal(props) {
  const [t] = useTranslation('ProfileWizard');
  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      props.onHide(false);
      props.onCrop(cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(true)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('CropPhotoModal.CROP_PHOTO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            style={{ height: 400, width: '100%' }}
            zoomTo={0.5}
            initialAspectRatio={3 / 4}
            preview=".img-preview"
            src={props.image}
            viewMode={1}
            cropBoxResizable={false}
            background={false}
            responsive={true}
            autoCropArea={1}
            dragMode="move"
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => {
              props.onHide(true);
            }}
          >
            {t('CropPhotoModal.CLOSE')}
          </button>
          <button type="button" className="btn btn-primary" onClick={getCropData}>
            {t('CropPhotoModal.CROP')}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
