import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  user: null,
  accessToken: "",
  accessTokenExpirationTime: null,
  refreshToken: "",
  fcmToken: null,
  userName: "",
  countryList: [],
  language: "gu",
  listLoading: false,
  actionsLoading: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState: initialAuthState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Set Login Detail
    login: (state, action) => {
      const entities = action.payload;
      state.actionsLoading = false;
      state.accessToken = entities.accessToken;
      state.accessTokenExpirationTime = entities.accessTokenExpirationTime;
      state.refreshToken = entities.refreshToken;
      state.user = entities.user;
    },
    logout: (state, action) => {
      state.actionsLoading = false;
      state.accessToken = "";
      state.accessTokenExpirationTime = null;
      state.refreshToken = "";
      state.user = null;
    },
    refresh: (state, action) => {
      const entities = action.payload;
      state.actionsLoading = false;
      state.accessToken = entities.accessToken;
      state.accessTokenExpirationTime = entities.accessTokenExpirationTime;
      state.refreshToken = entities.refreshToken;
    },
    setProfilePhoto: (state, action) => {
      const profileImage = action.payload;
      state.actionsLoading = false;
      state.user = {
        ...state.user,
        profileImage,
      };
    },
    setProfileStatus: (state, action) => {
      state.actionsLoading = false;
      if (!isNaN(action.payload)) {
        const profileStatus = Number(action.payload);
        state.user = {
          ...state.user,
          profileStatus,
        };
      }
    },
    setUserName: (state, action) => {
      const userName = action.payload;
      state.actionsLoading = false;
      state.userName = userName;
    },
    setProfile: (state, action) => {
      const data = action.payload;
      state.actionsLoading = false;
      state.user = {
        ...state.user,
        ...data.user,
      };
    },
    setProfileUpload: (state, action) => {
      const data = action.payload;
      state.actionsLoading = false;
      state.user = {
        ...state.user,
        ...data,
      };
    },
    // Fetched Country List
    countryListFetched: (state, action) => {
      const countryList = action.payload;
      state.listLoading = false;
      state.countryList = countryList;
    },

    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
  },
});
