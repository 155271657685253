import { createSlice } from "@reduxjs/toolkit";

const initialLandingState = {
  listLoading: false,
  actionsLoading: false,
  featuredProfileList: [],
  topicList: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const LandingSlice = createSlice({
  name: "Landing",
  initialState: initialLandingState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // Get Featured Profile List
    featureProfileFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.featuredProfileList = entities;
    },
    // Get Topic List
    topicListFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.topicList = entities;
    },
  },
});
